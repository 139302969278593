/* eslint-disable  */
// @ts-nocheck

//Unique table id's are manually generated, if adding a table please make sure to add id="privacy-00" change 00 to the next sequential 2 digit number for a11y

export default function GlobalPrivacyContent() {
  return (
    <>
      <div id="toppage" />
      <div className="container py-6 globalPrivacyContent">
        <style>
          {`.globalPrivacyContent a,
.globalPrivacyContent a:active,
.globalPrivacyContent a:focus,
.globalPrivacyContent a:link,
.globalPrivacyContent a:visited {
  color: #104c97;
  cursor: pointer;
  text-decoration: underline
}
.globalPrivacyContent h1,
.globalPrivacyContent h2,
.globalPrivacyContent h3,
.globalPrivacyContent h4,
.globalPrivacyContent h5,
.globalPrivacyContent h6 {
  color: #1d1d1d;
  text-transform: uppercase;
  font-family: var(--font-headline), var(--font-sans);
  line-height: 1.5
}

.globalPrivacyContent h1 {
  margin-right: 150px;
  font-size: 1.625rem;
  line-height: 1.5;
  margin-bottom: 8px
}

.globalPrivacyContent h2 {
  margin-right: 150px;
  font-size: 1.625rem;
  line-height: 1.5;
  margin-bottom: 8px
}

.globalPrivacyContent h2.alt {
  text-transform: uppercase;
  color: #9f9c9c
}

.globalPrivacyContent h3 {
  font-size: 1rem;
  margin-bottom: 7px;
  text-transform: none;
}

.globalPrivacyContent h4 {
  font-size: 1rem;
  margin-bottom: 7px;
  text-transform: none;
}

.globalPrivacyContent ul {
  list-style: square inside;
  margin: 16px 32px
}

.globalPrivacyContent ul li {
  margin-bottom: 16px;
  line-height: 1.5
}

.globalPrivacyContent ol {
  list-style-type: decimal;
  list-style-position: outside;
  margin-left: 32px
}

.globalPrivacyContent ol li {
  margin-bottom: 16px
}

.globalPrivacyContent p {
  margin-bottom: 16px;
  line-height: 1.5
}

.globalPrivacyContent table {
  width: 100%;
  margin: 8px 0 48px 0;
  border-collapse: collapse;
}

.globalPrivacyContent table.nested {
  background-image: none
}

.globalPrivacyContent table td,.globalPrivacyContent table th {
  padding: 5px 8px
}

.globalPrivacyContent table caption {
  display: none
}

.globalPrivacyContent table thead {
  font-family: Arial,Helvetica,sans-serif;
  font-size: 0.688rem;
  font-weight: 700;
  text-transform: uppercase;
  border-spacing: 8px
}

.globalPrivacyContent table thead tr {
  padding-bottom: 13px;
  border: medium none
}

.globalPrivacyContent table thead tr td,.globalPrivacyContent table thead tr th {
  background-image: none;
  vertical-align: middle;
  padding: 0
}

.globalPrivacyContent table thead {
  border: 1px solid #d7d7d7;
  background-color: #ededec
}

.globalPrivacyContent table thead div {
  padding: 5px
}

.globalPrivacyContent table tbody {
  background-color: #fff
}

.globalPrivacyContent table tbody tr {
  border: 1px solid #d7d7d7;
  background-color: #fff
}

.globalPrivacyContent table tbody tr.alt {
  background-color: #ededec
}

.globalPrivacyContent tbody tr td,.globalPrivacyContent tbody tr th {
  text-align: left;
  line-height: 1.7;
  font-size: 0.75rem
}

.globalPrivacyContent table tfoot {
  background-color: #fff
}

.globalPrivacyContent table.hhonors_table thead tr th {
  background-repeat: no-repeat;
  background-position: -111px 100%
}

.globalPrivacyContent table.hhonors_table thead tr th.first {
  background-position: 0 100%
}

.globalPrivacyContent table.hhonors_table thead tr th.action,.globalPrivacyContent table.hhonors_table thead tr th.end {
  background-position: 100% 100%
}

.globalPrivacyContent table.hhonors_table thead div {
  background-color: transparent;
  color: #1d1d1d;
  line-height: 1.5
}

.globalPrivacyContent table tbody tr.alt {
  background-color: #ffffff;
}

.bold-p {
  font-size: 0.875rem;
  font-weight: 800;
}

.privacy {
  border-collapse: collapse;
  width: 100%;
}

.privacy td,
.privacy th {
  border: 1px solid #ddd;
}

.privacy tr {
  background-color: #FFFFFF;
}

.privacy th {
  text-align: left;
  background-color: #f2f2f2;
  color: #000000;
  font-weight: bold;
}

hr {
  border: solid 1px #D8D8D6
}

[role=region][aria-label][tabindex] {
  overflow: auto;
}

[role=region][aria-label][tabindex]:focus {
  outline: 2px solid #104c97;
  outline-offset: 2px;
}`}
        </style>
        <h1>Hilton Global Privacy Statement</h1>
        <p>
          <strong>Last Updated: Sep 4, 2024</strong>
        </p>
        <ul type="disc">
          <li className="a">
            <a href="#Introduction">Introduction</a>
          </li>
          <li className="a">
            <a href="#CollectionGenerally">Collection of Personal Information – Generally</a>
          </li>
          <li className="a">
            <a href="#NoticeCollection">
              Collection of Personal Information – Notice at Point of Collection
            </a>
          </li>
          <li className="a">
            <a href="#PartnerAccommodations">Reservations with Hilton Partner Accommodations</a>
          </li>
          <li className="a">
            <a href="#Cookies">Cookies and Other Technologies</a>
          </li>
          <li className="a">
            <a href="#Analytics">Analytics and Interest-Based Advertisements</a>
          </li>
          <li className="a">
            <a href="#ChangingInfo">Changing and Accessing Your Personal Information</a>
          </li>
          <li className="a">
            <a href="#Protecting">How We Protect Personal Information</a>
          </li>
          <li className="a">
            <a href="#Disclosure">
              Disclosure Pursuant to Legal Obligations or to Protect the Safety and Security of
              People and Property
            </a>
          </li>
          <li className="a">
            <a href="#Marketing">Marketing Communications Choices</a>
          </li>
          <li className="a">
            <a href="#IntlTransfers">International Transfers of Personal Information</a>
          </li>
          <li className="a">
            <a href="#DataRetention">Data Retention Periods</a>
          </li>
          <li className="a">
            <a href="#Revisions">Changes to this Statement</a>
          </li>
          <li className="a">
            <a href="#ContactUs">Contact Information for Questions or Concerns</a>
          </li>
          <li className="a">
            <a href="#CaliforniaRights">Additional Provisions Applicable to California Consumers</a>
          </li>
          <li className="a">
            <a href="#NevadaRights">Additional Provision Applicable to Nevada Consumers</a>
          </li>
          <li className="a">
            <a href="#FrenchRights">Additional Provision Applicable to French Residents</a>
          </li>
          <li className="a">
            <a href="#HongKongRights">Additional Provision Applicable to Hong Kong Consumers</a>
          </li>
          <li className="a">
            <a href="#KoreanDataRights">Additional Provision Applicable to Korean Data Subjects</a>
          </li>
          <li className="a">
            <a href="#AppendixA">
              Appendix A: Additional Provisions Applicable to Processing of Personal Information of
              Residents of Certain Countries and U.S. States
            </a>
          </li>
          <li className="a">
            <a href="#AppendixB">
              Appendix B: Additional Provisions Applicable to Processing of Personal Information of
              Data Subjects in Türkiye
            </a>
          </li>
          <li className="a">
            <a href="#AppendixC">
              Appendix C: Additional Provisions Applicable to Processing of Personal Information of
              China Residents/Citizens
            </a>
          </li>
          <li className="a">
            <a href="#AppendixD">
              Appendix D: Additional Provisions Applicable to Processing of Personal Information of
              Brazil Residents or Citizens
            </a>
          </li>
          <li className="a">
            <a href="#AppendixE">
              Appendix E: Additional Provisions Applicable to Processing of Personal Information of
              Data Subjects of Monaco
            </a>
          </li>
        </ul>

        {/* Section 1 */}
        <h2>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content*/}
          <a name="Introduction" />
          Introduction
        </h2>
        <p>
          Hilton's mission is to be the most hospitable company in the world. We're passionate about
          delivering exceptional guest experiences, and we look forward to welcoming you to our
          hotels so we can share the light and warmth of hospitality with you.
        </p>
        <p>
          We pledge to deliver the highest level of customer service, which includes respecting your
          privacy and protecting your personal information. In this privacy statement ("Statement"),
          we provide you with details about how we collect, use, and disclose your personal
          information.
        </p>
        <p>
          This Statement applies to Hilton Worldwide Holdings Inc., its subsidiaries, and all of the
          hotels within the Hilton Portfolio of Brands (collectively, "Hilton," "we," or "us"). Our
          Portfolio of Brands includes Waldorf Astoria Hotels &amp; Resorts, LXR Hotels &amp;
          Resorts, Conrad Hotels &amp; Resorts, Canopy by Hilton, Signia by Hilton, Hilton Hotels
          &amp; Resorts, Curio Collection by Hilton, DoubleTree by Hilton, Tapestry Collection by
          Hilton, Embassy Suites by Hilton, Tempo by Hilton, Motto by Hilton, Hilton Garden Inn,
          Hampton by Hilton, Tru by Hilton, Spark by Hilton, Homewood Suites by Hilton, Home2 Suites
          by Hilton, LivSmart Studios by Hilton, Graduate by Hilton, and NoMad.
        </p>
        <p>
          By using any of our products or services and/or by agreeing to this Statement, e.g. in the
          context of registering for any of our products or services, you understand and acknowledge
          that we will collect and use personal information as described in this Statement.
        </p>
        <p>
          Please note that this Statement does not apply to our processing of personal information
          on behalf of and subject to the instructions of third parties such as airlines, car rental
          companies and other service providers, companies that organize or offer packaged travel
          arrangements, marketing partners, or certain corporate customers.
        </p>
        <p>
          <font style={{ fontSize: 10 }}>
            <sup>1</sup> Hilton Domestic Operating Company Inc. is the Hilton entity that is the
            data controller for all guest data and operates, among other things, Hilton&apos;s
            marketing activities. Hilton Domestic Operating Company Inc. is a subsidiary of Hilton
            Worldwide Holdings Inc.
          </font>
        </p>
        <p>
          <font style={{ fontSize: 10 }}>
            The Hilton Portfolio of Brands includes managed, franchised, and joint venture hotels.
            Franchised hotels are operated by entities that are separate from Hilton. Joint venture
            hotels are operated by entities that are partially owned and/or controlled by Hilton. In
            addition to Hilton Domestic Operating Company Inc., those entities are also independent
            data controllers for guest data. <br />
            Hilton Grand Vacations (HGV) operates timeshare and fractional resorts. HGV is a
            third-party partner whose privacy statement is available at{' '}
            <a
              href="https://www.hiltongrandvacations.com/en/privacy-policy.html"
              target="_blank"
              rel="noreferrer"
            >
              https://www.hiltongrandvacations.com/en/privacy-policy.html
            </a>
            .
          </font>
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 2 */}
        <h2>
          <a name="CollectionGenerally" />
          Collection of Personal Information - Generally
        </h2>
        <p>
          The following summarizes the categories of personal information we have collected in the
          past 12 months, the sources for those categories of personal information, the business or
          commercial purposes for which the information was collected, the categories of third
          parties with whom the information may have been shared, as permitted by law, and whether
          the information was sold to a third party for a business or commercial purpose within the
          past 12 months. Certain categories of personal information collected are required in order
          for us to provide you with the requested products or services, and if you choose not to
          provide us with such information, we may not be able to provide you with these products or
          services. If consent is required, we will obtain your consent before selling or sharing
          your personal information.
        </p>
        <div role="region" aria-label="Collection of Personal Information" tabindex="0">
          <table id="privacy-01" className="privacy">
            <tbody>
              <tr>
                <th valign="top" width="20%">
                  <p>
                    We have collected the following categories of personal information in the past
                    12 months:
                  </p>
                </th>
                <th valign="top" width="20%">
                  <p>We have obtained this personal information from the following sources:</p>
                </th>
                <th valign="top" width="20%">
                  <p>
                    We collected this personal information for the following business or commercial
                    purposes:
                  </p>
                </th>
                <th valign="top" width="20%">
                  <p>
                    We have shared this personal information with the following categories of third
                    parties:
                  </p>
                </th>
                <th valign="top" width="20%">
                  <p>This information was sold to a third party within the past 12 months</p>
                </th>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Name</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                    <li className="a">From third party partners such as credit card providers</li>
                    <li className="a">From demographics companies</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill guest reservations</li>
                    <li className="a">Send guests communications relating to their reservations</li>
                    <li className="a">
                      Administer membership and activity in the Hilton Honors loyalty program
                    </li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Verify identity</li>
                    <li className="a">
                      Send marketing communications via email, direct mail, and social media
                    </li>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Determine guests' eligibility for special or promotional rates
                    </li>
                    <li className="a">
                      Process payments, including conducting strong customer authentication (SCA)
                      when required by law
                    </li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">Administer contests and sweepstakes</li>
                    <li className="a">Optimize our websites and mobile apps</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Service providers who perform tasks on behalf of Hilton, which may include
                      those who send marketing communications on our behalf
                    </li>
                    <li className="a">Hilton Grand Vacations, Hilton's third-party partner</li>
                    <li className="a">Payment card issuers and payment processors</li>
                    <li className="a">
                      Employers pursuant to corporate agreements, employee consent, and as permitted
                      by law
                    </li>
                    <li className="a">
                      Entities that have arranged a special rate plan with Hilton and/or organized
                      an event and/or room block with Hilton
                    </li>
                    <li className="a">
                      The third-party booking platform or travel agent through which a guest made a
                      reservation
                    </li>
                    <li className="a">
                      Government agencies pursuant to legal requirements in some countries
                    </li>
                    <li className="a">
                      Third-party advertisers, for optimization and personalization on their
                      platforms
                    </li>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Telephone</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                    <li className="a">
                      From third parties such as travel booking platforms and credit card providers
                    </li>
                    <li className="a">From demographics companies</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill guest reservations</li>
                    <li className="a">Send guests communications relating to their reservations</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Verify identity</li>
                    <li className="a">Send marketing communications via SMS</li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">Authenticate mobile account</li>
                    <li className="a">Sending service-related SMS communications</li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">
                      Engage in sales activity for group bookings, meetings and events
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Service providers who perform tasks on behalf of Hilton, which may include
                      those who send marketing communications on our behalf
                    </li>
                    <li className="a">Hilton Grand Vacations</li>
                    <li className="a">
                      Employers pursuant to corporate agreements, employee consent, and as permitted
                      by law
                    </li>
                    <li className="a">
                      The third-party travel booking platform or travel agent through which a guest
                      made a reservation
                    </li>
                    <li className="a">
                      Third-party advertisers, for optimization and personalization on their
                      platforms
                    </li>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Email address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                    <li className="a">
                      From third parties such as travel booking platforms and credit card providers
                    </li>
                    <li className="a">From demographics companies</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill guest reservations</li>
                    <li className="a">Send guests communications relating to their reservations</li>
                    <li className="a">
                      Administer membership and activity in the Hilton Honors loyalty program
                    </li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Verify identity</li>
                    <li className="a">Send marketing communications via email</li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">Administer contests and sweepstakes</li>
                    <li className="a">
                      Share that data with Hilton Grand Vacations, Hilton's third-party partner
                    </li>
                    <li className="a">Authenticate mobile account</li>

                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">
                      Engage in sales activity for group bookings, meetings, and events
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Service providers who perform tasks on behalf of Hilton, which may include
                      those who send marketing communications on our behalf
                    </li>
                    <li className="a">Hilton Grand Vacations</li>
                    <li className="a">
                      Employers pursuant to corporate agreements, employee consent, and as permitted
                      by law
                    </li>
                    <li className="a">
                      The third-party travel booking platform or travel agent through which a guest
                      made a reservation
                    </li>
                    <li className="a">
                      Third-party advertisers, for optimization and personalization on their
                      platforms
                    </li>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Home address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                    <li className="a">
                      From third parties such as travel booking platforms and credit card providers
                    </li>
                    <li className="a">From demographics companies</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill guest reservations</li>
                    <li className="a">
                      Administer membership and activity in the Hilton Honor loyalty program
                    </li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Verify identity</li>
                    <li className="a">Send marketing communications via direct mail</li>
                    <li className="a">
                      Perform analytics to provide guests with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Process payments, including conducting SCA when required by law
                    </li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">Administer contests and sweepstakes</li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">
                      Engage in sales activity for group bookings, meetings, and events
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Service providers who perform tasks on behalf of Hilton</li>
                    <li className="a">Hilton Grand Vacations</li>
                    <li className="a">Payment card issuers and payment processors</li>
                    <li className="a">
                      Employers pursuant to corporate agreements, employee consent, and as permitted
                      by law
                    </li>
                    <li className="a">
                      The third-party travel booking platform or travel agent through which a guest
                      made a reservation
                    </li>
                    <li className="a">
                      Third-party advertisers, for optimization and personalization on their
                      platforms
                    </li>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Nationality</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Comply with applicable laws that require us to collect this information in
                      some jurisdictions
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Government agencies pursuant to legal requirements in some countries
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Date of birth</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Comply with applicable laws that require us to collect this information in
                      some jurisdictions
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Government agencies pursuant to legal requirements in some countries
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Gender</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Comply with applicable laws that require us to collect this information in
                      some jurisdictions
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Government agencies pursuant to legal requirements in some countries
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Payment card information</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                    <li className="a">
                      The third-party travel booking platform or travel agent through which a
                      consumer made a reservation
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Process payments, including conducting SCA when required by law
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Payment card issuers and payment processors</li>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Honors number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from Hilton Honors members themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill guest reservations</li>
                    <li className="a">Send guests communications relating to their reservations</li>
                    <li className="a">
                      Administer membership and activity in the Hilton Honors loyalty program
                    </li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Verify account</li>
                    <li className="a">
                      Send marketing communications via email, direct mail, and social media
                    </li>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Determine guests' eligibility for special or promotional rates
                    </li>
                    <li className="a">Process payments</li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">Administer contests and sweepstakes</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Service providers who perform tasks on behalf of Hilton, which may include
                      those who send marketing communications on our behalf
                    </li>
                    <li className="a">Hilton Grand Vacations</li>
                    <li className="a">
                      Employers pursuant to corporate agreements, employee consent, and as permitted
                      by law
                    </li>
                    <li className="a">
                      Third-party advertisers, for optimization and personalization on their
                      platforms
                    </li>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">WeChat ID</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Enable guests to log into Hilton Honors account</li>
                  </ul>
                </td>
                <td valign="top">
                  <p className="a">We do not share customers' WeChat ID with third parties</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Passport information</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Pursuant to laws that require the collection of such data</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Government agencies pursuant to legal requirements in some countries
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Preferred language</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Directly from consumers themselves, including from browser and computer
                      settings
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Provide you with a superior guest experience</li>
                    <li className="a">Send you communications relating to Hilton Honors</li>
                    <li className="a">Send you marketing communications</li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Grand Vacations</li>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Room preference</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Fulfill your reservations</li>
                    <li className="a">Personalize your experience</li>
                    <li className="a">
                      Perform analytics to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Room selection and assignment</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Guests can select their rooms on the Hilton Honors app and Hilton assigns
                      rooms
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Fulfill reservations</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Arrival time</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Fulfill reservations</li>
                    <li className="a">Personalize guest experience</li>
                    <li className="a">Provide customer service and support</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Departure date</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Fulfill reservations</li>
                    <li className="a">Personalize guest experience</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Additional guest names</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill reservations</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Grand Vacations</li>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">
                      Corporate travel planner contact information (name, title, company, business
                      phone, email and address)
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from the corporate contacts themselves</li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill and manage corporate travel reservations</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">
                      Send marketing communications via email, direct mail, and social media
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Employers pursuant to corporate agreements, employee consent, and as permitted
                      by law
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">
                      Business contact data for members of the media and social media influencers
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">From a third-party communications service provider</li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Contact members of the media and social media influencers in their business
                      capacity
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>
                    We do not share business contact data for members of the media and social media
                    influencers with third parties
                  </p>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Corporate number and name</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill reservations</li>
                    <li className="a">
                      Determine guests' eligibility for special or promotional rates
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Employers pursuant to corporate agreements, employee consent, and as permitted
                      by law
                    </li>
                    <li className="a">Hilton Grand Vacations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Travel agent number and name</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                    <li className="a">From travel agents</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Properly attribute reservations to travel agents</li>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">
                      Determine your eligibility for special or promotional rates
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Grand Vacations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Airline partner number and name</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Properly attribute points to consumers who participate in airline loyalty
                      programs
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">The respective airlines</li>
                    <li className="a">Hilton Grand Vacations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Vehicle information</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                    <li className="a">From license plate recognition technology</li>
                    <li className="a">From video capabilities</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Enable guests to park at our properties</li>
                    <li className="a">Ensure safety and security</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>We do not share vehicle information with any third parties.</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Images or footage captured by closed circuit television (CCTV)
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from cameras located on and around the property</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Protect guests, employees and visitors to properties</li>
                    <li className="a">
                      Comply with applicable laws that require us to collect this information in
                      some jurisdictions
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Government agencies pursuant to legal requirements in some countries
                    </li>
                    <li className="a">
                      Employers pursuant to corporate agreements, employee consent, and as permitted
                      by law
                    </li>
                    <li className="a">
                      Entities that have arranged a special rate plan with Hilton and/or organized
                      an event and/or room block with Hilton, as permitted by law
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Promotional images or footage captured by on-property photographers
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from cameras utilized on the property</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Internal and external promotions and communications</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>We share promotional images with third parties that facilitate promotions.</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">
                      Internet or other electronic network activity information, including
                      information regarding a customer's interaction with Hilton websites,
                      applications, or advertisements
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Directly from consumers themselves via Cookies, server logs, web beacons,
                      tags, pixels, and other similar technologies
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">Marketing</li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">Optimize our websites and mobile apps</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Advertising networks</li>
                    <li className="a">
                      Analytics providers for our websites and mobile applications
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>Yes</p>
                  <p>
                    <strong>Note: </strong>If in the Hilton Honors iOS App you ask the App not to
                    track you across other companies' apps and websites, Hilton will honor that
                    request. Please note that you may still receive emails about properties you
                    searched for either directly within the Hilton Honors App, or on Hilton.com. You
                    may also receive emails related to Hilton-American Express Co-Branded Credit
                    Cards; those emails are not sent based oniOS tracking.
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">IP addresses</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Directly from consumers as a result of their browsing activity on the internet
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">
                      Conduct SCA as part of the payment process, when required by law
                    </li>
                    <li className="a">Optimize our websites and mobile apps</li>
                    <li className="a">Troubleshoot and resolve website issues</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Advertising networks</li>
                    <li className="a">
                      Analytics providers for our websites and mobile applications
                    </li>
                    <li className="a">
                      Service providers who perform tasks on behalf of Hilton, which may include
                      those who send marketing communications on our behalf{' '}
                    </li>
                    <li className="a">
                      Payment card issuers and payment processors (only when conducting SCA)
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Session IDs</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Directly from consumers as a result of their browsing activity on the internet
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Marketing</li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Advertising networks</li>
                    <li className="a">
                      Analytics providers for our websites and mobile applications
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Booking engine</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Directly from consumers as a result of their browsing activity on the internet
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Show you targeted advertisements on media</li>
                    <li className="a">Support marketing efforts</li>
                    <li className="a">Provide customer service</li>
                    <li className="a">Perform analytics to improve business operations</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Advertising networks</li>
                    <li className="a">
                      Analytics providers for our websites and mobile applications
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Streaming service usage (including service used, hours of use, launch method,
                      and log-out method)
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Directly from consumer as a result of their logging into a streaming service
                      through Connected Room functionality
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Evaluate the usage of different streaming services</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>We do not share streaming service usage with third parties</p>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">
                      Whether you have a Hilton and American Express Co-Branded Credit Card
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">From American Express</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Provide you with benefits associated with your co-branded card
                    </li>
                    <li className="a">Show you targeted advertisements on media</li>
                    <li className="a">Support marketing efforts</li>
                    <li className="a">Provide customer service</li>
                    <li className="a">Perform analytics to improve business operations</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Advertising networks</li>
                    <li className="a">
                      Analytics providers for our websites and mobile applications
                    </li>
                    <li className="a">Hilton Honors affiliated partners</li>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Whether your Hilton and Amazon accounts are linked</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">From Amazon</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Facilitate use of Honors points as part of Amazon&apos;s Shop with Points
                      program
                    </li>
                    <li className="a">Show you targeted advertisements on media</li>
                    <li className="a">Support marketing efforts</li>
                    <li className="a">Provide customer service</li>
                    <li className="a">Perform analytics to improve business operations</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Advertising networks</li>
                    <li className="a">
                      Analytics providers for our websites and mobile applications
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Whether your Hilton and Lyft accounts are linked</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">From Lyft</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Show you targeted advertisements on media</li>
                    <li className="a">Support marketing efforts</li>
                    <li className="a">Provide customer service</li>
                    <li className="a">Perform analytics to improve business operations</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Advertising networks</li>
                    <li className="a">
                      Analytics providers for our websites and mobile applications
                    </li>
                    <li className="a">Hilton Honors affiliated partners</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Geolocation information</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Directly from consumers as a result of their browsing activity on the internet
                      and use of the Hilton Honors app
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Enable guest access to guest room and hotel facilities</li>
                    <li className="a">Fulfill Digital Key sharing requests</li>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Marketing</li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Advertising networks</li>
                    <li className="a">
                      Analytics providers for our websites and mobile applications
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Device information (including MAC address)</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Directly from consumers as a result of their browsing activity on the
                      internet, scanning of QR codes on property, and use of the Hilton Honors app
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Perform analytics to provide guests with personalized offers and content
                    </li>
                    <li className="a">Marketing</li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Advertising networks</li>
                    <li className="a">
                      Analytics providers for our websites and mobile applications
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>Yes</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Social media information; publicly available online information (including
                      through social media platforms)
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                    <li className="a">
                      Indirectly from consumers by reviewing publicly available information on their
                      social media channels
                    </li>
                    <li className="a">From publicly available online sources</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">
                      Respond to service concerns in order to better serve our guests
                    </li>
                    <li className="a">Provide a more personalized welcome and stay experience</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>We do not share social media information with any third parties.</p>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Demographics data</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      From third-party companies that provide demographics data.
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">
                      Link with customer information that Hilton already has on file
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>We do not share demographics data with any third parties.</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Your MyWay preferences</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Send you the communications you would like to receive</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Partner Accommodations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>

              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Your email and consent preferences (such as opt-out or opt-in, as applicable)
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Send you the communications you would like to receive</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Advertising networks</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>

              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Description of an incident that occurs at a Hilton property or involves a
                      Hilton employee, including incidents related to safety, security, violation of
                      law, violation of applicable policy, or violation of contractual obligation
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                    <li className="a">Directly from Hilton employees</li>
                    <li className="a">Directly from law enforcement</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Respond to safety & security concerns</li>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Comply with legal or contractual obligations</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Law enforcement</li>
                    <li className="a">
                      Employers pursuant to corporate agreements, employee consent, and as permitted
                      by law
                    </li>
                    <li className="a">
                      Entities that have arranged a special rate plan with Hilton and/or organized
                      an event and/or room block with Hilton
                    </li>
                    <li className="a">
                      The management and/or owners of a franchise property if the incident relates
                      to that property
                    </li>
                    <li className="a">Others as required by law</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">
                      Description of a complaint that you make to us, including your free form
                      textual feedback if you are a Hilton Honors member
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Respond to safety and security concerns</li>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Comply with legal or contractual obligations</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      The management and/or owners of a franchise property if your complaint relates
                      to that property.
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Customer ratings and survey responses</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Troubleshoot and resolve website issues</li>
                    <li className="a">Improve customer experience</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>
                    We do not share identifiable customer ratings and survey responses with any
                    third parties.
                  </p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Free form textual feedback</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Troubleshoot and resolve website issues</li>
                    <li className="a">Improve customer experience</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>We do not share your feedback with any third parties.</p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">
                      Spa and wellness service or treatment details (such as date, time, and type of
                      service){' '}
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Directly from consumers themselves</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Respond to safety and security concerns</li>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Comply with legal or contractual obligations</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>
                    We do not share your spa and wellness service or treatment details with any
                    third parties.
                  </p>
                </td>
                <td valign="top">
                  <p>No</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 3 */}
        <h2>
          <a name="NoticeCollection" />
          Collection of Personal Information – Notice at Point of Collection
        </h2>
        <h3 className="a">Browsing Hilton Websites</h3>
        <p>
          When you browse a Hilton website, we collect the following categories of personal
          information about you, which are used for the following purposes:
        </p>
        <div
          role="region"
          aria-label="Collection of Personal Information Browsing Hilton Websites"
          tabindex="0"
        >
          <table id="privacy-02" className="privacy">
            <tbody>
              <tr>
                <th valign="top">
                  <p>We collect the following categories of personal information…</p>
                </th>
                <th valign="top">
                  <p>We collect this information in order to…</p>
                </th>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">
                      Internet or other electronic network activity information, including
                      information regarding your interaction with Hilton websites, applications, or
                      advertisements
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Show you targeted advertisements on marketing efforts including media, email,
                      and chat (e.g., if you searched for Hilton properties in New York City, then
                      when you visit a non-Hilton website, we may show you an ad for a Hilton
                      property in New York City)
                    </li>
                    <li className="a">Optimize our websites and mobile apps</li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Marketing</li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">IP address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Show you targeted advertisements on media </li>
                    <li className="a">Optimize our websites and mobile apps</li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">
                      Conduct SCA as part of the payment process, when required by law
                    </li>
                    <li className="a">Troubleshoot and resolve website issues</li>
                    <li className="a">Detect and prevent fraud </li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Session ID</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Marketing</li>
                    <li className="a">Show you targeted advertisements on media</li>
                    <li className="a">Optimize our websites and mobile apps</li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Booking engine</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Show you targeted advertisements on media</li>
                    <li className="a">Support marketing efforts</li>
                    <li className="a">Provide customer service</li>
                    <li className="a">Perform analytics to improve business operations</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Whether your Hilton and Lyft accounts are linked</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Show you targeted advertisements on media</li>
                    <li className="a">Support marketing efforts</li>
                    <li className="a">Provide customer service</li>
                    <li className="a">Perform analytics to improve business operations</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          If you are a Hilton Honors member and you log into your account during your browsing
          session, then we collect the following information about you, which are used for the
          following purposes:
        </p>
        <div
          role="region"
          aria-label="Collection of Personal Information Browsing Hilton Websites as a Hilton Honors Member"
          tabindex="0"
        >
          <table id="privacy-03" className="privacy">
            <tbody>
              <tr>
                <th valign="top">
                  <p>We collect the following categories of personal information…</p>
                </th>
                <th valign="top">
                  <p>We collect this information in order to…</p>
                </th>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">
                      Internet or other electronic network activity information, including
                      information regarding your interaction with Hilton websites, applications, or
                      advertisements
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Show you targeted advertisements on marketing efforts including media, email,
                      and chat (e.g., if you searched for Hilton properties in New York City, then
                      when you visit a non-Hilton website, we may show you an ad for a Hilton
                      property in New York City)
                    </li>
                    <li className="a">Optimize our websites and mobile apps</li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Marketing</li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">IP address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">
                      Conduct SCA as part of the payment process, when required by law
                    </li>
                    <li className="a">Show you targeted advertisements on media</li>
                    <li className="a">Optimize our websites and mobile apps</li>
                    <li className="a">Troubleshoot and resolve website issues</li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Session ID</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Show you targeted advertisements on media</li>
                    <li className="a">Optimize our websites and mobile apps</li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Customer ratings and survey responses</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Troubleshoot and resolve website issues</li>
                    <li className="a">Improve customer experience</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Free form textual feedback</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Troubleshoot and resolve website issues</li>
                    <li className="a">Improve customer experience</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Honors number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Look up and verify your Hilton Honors account and reservation
                    </li>
                    <li className="a">Fulfill guest reservations</li>
                    <li className="a">Send guests communications relating to their reservations</li>
                    <li className="a">
                      Administer membership and activity in the Hilton Honors loyalty program
                    </li>
                    <li className="a">Support marketing efforts</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Troubleshoot and resolve website issues</li>
                    <li className="a">
                      Send marketing communications via email, direct mail, and social media
                    </li>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Determine guests’ eligibility for special or promotional rates
                    </li>
                    <li className="a">Process payments</li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                    <li className="a">Administer contests and sweepstakes</li>
                    <li className="a">Detect and prevent fraud </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Honors tier</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Support marketing efforts</li>
                    <li className="a">Provide customer service</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                    <li className="a">Troubleshoot and resolve website issues</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3 className="a">Making a Reservation</h3>
        <p>
          When you make a reservation at a Hilton property, we collect the following categories of
          personal information about you, which are used for the following purposes:
        </p>
        <div
          role="region"
          aria-label="Collection of Personal Informaiton Making a Reservation"
          tabindex="0"
        >
          <table id="privacy-04" className="privacy">
            <tbody>
              <tr>
                <th valign="top">
                  <p>We collect the following categories of personal information…</p>
                </th>
                <th valign="top">
                  <p>We collect this information in order to…</p>
                </th>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Name</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Look up and verify your reservation</li>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">Send you communications relating to your reservation</li>
                    <li className="a">
                      Administer membership and activity in the Hilton Honors loyalty program
                    </li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">
                      Send you marketing communications via email and direct mail
                    </li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Determine your eligibility for special or promotional rates
                    </li>
                    <li className="a">Process payments</li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">
                      Share this information with Hilton Grand Vacations, Hilton's third-party
                      partner and Hilton Partner Accommodations
                    </li>
                    <li className="a">Administer contests and sweepstakes</li>
                    <li className="a">Optimize our websites and mobile apps</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Additional names</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Honors number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Look up and verify your Hilton Honors account and reservation
                    </li>
                    <li className="a">Administer your membership in Hilton Honors</li>
                    <li className="a">Fulfill guest reservations</li>
                    <li className="a">Send guests communications relating to their reservations</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">
                      Send marketing communications via email, direct mail, and social media
                    </li>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Determine guests’ eligibility for special or promotional rates
                    </li>
                    <li className="a">Process payments</li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                    <li className="a">Administer contests and sweepstakes</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Phone number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Verify identity</li>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">
                      Send you text messages relating to any services you may need during your stay
                    </li>
                    <li className="a">Send marketing communications via SMS</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">
                      Share this information with Hilton Grand Vacations, Hilton's third-party
                      partner and Hilton Partner Accommodations
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">
                      Engage in sales activity for group bookings, meetings, and events
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">Send you communications relating to your reservation</li>
                    <li className="a">Send you marketing communications</li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">Process payments</li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">
                      Share this information with Hilton Grand Vacations, Hilton's third-party
                      partner and Hilton Partner Accommodations
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Address type (home/work)</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Email address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Look up and verify your Hilton Honors account and reservation
                    </li>
                    <li className="a">
                      Associate your reservation with your Hilton Honors account
                    </li>
                    <li className="a">Send you communications relating to your reservation</li>
                    <li className="a">Send you marketing communications</li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Determine your eligibility for special or promotional rates
                    </li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">
                      Share this information with Hilton Grand Vacations and Hilton Partner
                      Accommodations
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">Authenticate mobile account</li>
                    <li className="a">
                      Engage in sales activity for group bookings, meetings, and events
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Preferred language</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Provide you with a superior guest experience</li>
                    <li className="a">Send you communications relating to your reservation</li>
                    <li className="a">Send you marketing communications</li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">
                      Share this information with Hilton Grand Vacations and Hilton Partner
                      Accommodations
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Payment card information</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Process payments</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Room preference</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">Personalize your experience</li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Corporate name and number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">
                      Determine your eligibility for special or promotional rates
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Travel agent name and number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">
                      Determine your eligibility for special or promotional rates
                    </li>
                    <li className="a">Properly attribute reservations to travel agents</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Airline partner name and number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Credit points to you if you participate in an airline loyalty program
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3 className="a">Enrolling in Hilton Honors</h3>
        <p>
          When you enroll in Hilton Honors, we collect the following categories of personal
          information about you, which are used for the following purposes:
        </p>
        <div
          role="region"
          aria-label="Collection of Personal Information Enrolling in Hilton Honors"
          tabindex="0"
        >
          <table id="privacy-05" className="privacy">
            <tbody>
              <tr>
                <th valign="top">
                  <p>We collect the following categories of personal information…</p>
                </th>
                <th valign="top">
                  <p>We collect this information in order to…</p>
                </th>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Name</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Administer your membership in the Hilton Honors loyalty program
                    </li>
                    <li className="a">Verify your Hilton Honors account</li>
                    <li className="a">Send you communications relating to Hilton Honors</li>
                    <li className="a">Send you marketing communications</li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Determine your eligibility for special or promotional rates
                    </li>
                    <li className="a">Process payments</li>
                    <li className="a">
                      Process transactions with Hilton Honors affiliated partners (such as
                      Points.com and Lyft)
                    </li>
                    <li className="a">
                      Share this information with Hilton Grand Vacations, Hilton's third-party
                      partner and Hilton Partner Accommodations
                    </li>
                    <li className="a">Administer contests and sweepstakes</li>
                    <li className="a">Optimize our websites and mobile apps</li>

                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Phone number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Administer your membership in the Hilton Honors loyalty program
                    </li>
                    <li className="a">Verify your Hilton Honors account</li>
                    <li className="a">
                      Send you text messages relating to any services you may need during your stays
                      at Hilton properties
                    </li>
                    <li className="a">Authenticate mobile account</li>
                    <li className="a">Send service-related SMS communications</li>
                    <li className="a">Provide customer service and support</li>

                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">
                      Share this information with Hilton Grand Vacations and Hilton Partner
                      Accommodations
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">
                      Engage in sales activity for group bookings, meetings, and events
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Administer your membership in the Hilton Honors loyalty program
                    </li>
                    <li className="a">Verify your Hilton Honors account</li>
                    <li className="a">Send you communications relating to Hilton Honors</li>
                    <li className="a">Send you marketing communications</li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Determine your eligibility for special or promotional rates
                    </li>
                    <li className="a">Process payments</li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">
                      Engage in sales activity for group bookings, meetings, and events
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Email address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Administer your membership in the Hilton Honors loyalty program
                    </li>
                    <li className="a">Verify your Hilton Honors account and reservation</li>
                    <li className="a">
                      Associate your reservation with your Hilton Honors account
                    </li>
                    <li className="a">Send you communications relating to Hilton Honors</li>
                    <li className="a">Send you marketing communications</li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Determine your eligibility for special or promotional rates
                    </li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">
                      Share this information with Hilton Grand Vacations and Hilton Partner
                      Accommodations
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">Authenticate mobile account</li>
                    <li className="a">
                      Engage in sales activity for group bookings, meetings, and events
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Preferred language</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Provide you with a superior guest experience</li>
                    <li className="a">Send you communications relating to Hilton Honors</li>
                    <li className="a">Send you marketing communications</li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">
                      Share this information with Hilton Grand Vacations and Hilton Partner
                      Accommodations
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Payment card information</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Process payments</li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Room preference</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill your reservations</li>
                    <li className="a">Personalize your experience</li>
                    <li className="a">
                      Perform analytics in order to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Corporate name and number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">
                      Determine your eligibility for special or promotional rates
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Travel agent name and number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">
                      Determine your eligibility for special or promotional rates
                    </li>
                    <li className="a">Properly attribute reservations to travel agents</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Airline partner name and number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Credit points to you if you participate in an airline loyalty program
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">My Way preferences</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Provide you with a superior guest experience</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Email subscription preferences</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Send you the communications you would like to receive</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Opt-out preferences</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Send you the communications you would like to receive</li>
                    <li className="a">Better serve our guests</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3 className="a">Checking In at a Hilton Property</h3>
        <p>
          When you check in at a Hilton property, we may collect the following categories of
          personal information about you, which are used for the following purposes:
        </p>
        <div
          role="region"
          aria-label="Collection of Personal Information Checking In at a Hilton Property"
          tabindex="0"
        >
          <table id="privacy-06" className="privacy">
            <tbody>
              <tr>
                <th valign="top">
                  <p>We collect the following categories of personal information…</p>
                </th>
                <th valign="top">
                  <p>We collect this information in order to…</p>
                </th>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Name</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Look up and verify your reservation</li>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Payment card information</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Receive payment for your reservation</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Additional guest names</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill your reservation</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Passport information</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Comply with applicable laws that require us to collect this information in
                      some jurisdictions
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Comply with applicable laws that require us to collect this information in
                      some jurisdictions
                    </li>
                    <li className="a">Verify your Hilton Honors account</li>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">Send you communications relating to your reservation</li>
                    <li className="a">Send you marketing communications </li>
                    <li className="a">
                      Perform analytics to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">Process payments</li>
                    <li className="a">Process transactions with partners </li>
                    <li className="a">
                      Share this information with Hilton Grand Vacations, Hilton’s third-party
                      partner
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Nationality</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Comply with applicable laws that require us to collect this information in
                      some jurisdictions
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Date of birth</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Comply with applicable laws that require us to collect this information in
                      some jurisdictions
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Gender</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Comply with applicable laws that require us to collect this information in
                      some jurisdictions
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Hilton Honors number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">
                      Look up and verify your Hilton Honors account and reservation
                    </li>
                    <li className="a">Administer your membership in Hilton Honors</li>
                    <li className="a">Support marketing efforts</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Fulfill guest reservations</li>
                    <li className="a">Send guests communications relating to their reservations</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">
                      Send marketing communications via email, direct mail, and social media
                    </li>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Determine guests’ eligibility for special or promotional rates
                    </li>
                    <li className="a">Process payments</li>
                    <li className="a">Process transactions with partners</li>
                    <li className="a">Administer contests and sweepstakes</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Frequent flyer number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">To facilitate the award of loyalty points</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Email address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Send folios after the completion of a stay</li>
                    <li className="a">
                      Associate your reservation with your Hilton Honors account
                    </li>
                    <li className="a">Send you marketing communications</li>
                    <li className="a">
                      Perform analytics to provide you with personalized offers and content
                    </li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">
                      Determine your eligibility for special or promotional rates
                    </li>
                    <li className="a">Process transactions with partners </li>
                    <li className="a">Share this information with Hilton Grand Vacations</li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">Authenticate mobile account</li>
                    <li className="a">
                      Engage in sales activity for group bookings, meetings, and events
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3 className="a">E-Check In</h3>
        <p>
          When you check in using e-check in, we collect the following categories of personal
          information about you, which are used for the following purposes:
        </p>
        <div
          role="region"
          aria-label="Collection of Personal Information using E-check In"
          tabindex="0"
        >
          <table id="privacy-07" className="privacy">
            <tbody>
              <tr>
                <th valign="top">
                  <p>We collect the following categories of personal information…</p>
                </th>
                <th valign="top">
                  <p>We collect this information in order to…</p>
                </th>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Payment card information</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Receive payment for your reservation</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Room selection and assignment</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">Better serve our guests</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Arrival time</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">Personalize your experience</li>
                    <li className="a">Provide customer service and support</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Departure date</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Fulfill reservations</li>
                    <li className="a">Personalize guest experience</li>
                    <li className="a">Provide customer service and support</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3 className="a">Digital Key</h3>
        <p>
          When you use Digital Key, we collect the following categories of personal information
          about you, which are used for the following purposes:
        </p>
        <div
          role="region"
          aria-label="Collection of Personal Information using Digital Key"
          tabindex="0"
        >
          <table id="privacy-08" className="privacy">
            <tbody>
              <tr>
                <th valign="top">
                  <p>We collect the following categories of personal information…</p>
                </th>
                <th valign="top">
                  <p>We collect this information in order to…</p>
                </th>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Payment card information</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Receive payment for your reservation</li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Room selection and assignment</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">Better serve our guests</li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Arrival time</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Fulfill your reservation</li>
                    <li className="a">Personalize your experience</li>
                    <li className="a">Provide customer service and support</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Departure date</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Better serve our guests</li>
                    <li className="a">Fulfill reservations</li>
                    <li className="a">Personalize your experience</li>
                    <li className="a">Provide customer service and support</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Precise geo-location information</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Enable you to access your guest room and hotel facilities</li>
                    <li className="a">Fulfill Digital Key sharing requests</li>
                    <li className="a">
                      Perform analytics in order to provide guests with personalized offers and
                      content
                    </li>
                    <li className="a">Marketing</li>
                    <li className="a">
                      Share that data with advertising networks who serve personalized
                      advertisements
                    </li>
                    <li className="a">Detect and prevent fraud</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h3 className="a">Guest Assistance</h3>
        <p>
          When you contact Guest Assistance, we collect the following categories of personal
          information about you, which are used for the following purposes:
        </p>
        <div
          role="region"
          aria-label="Collection of Personal Information when Contacting Guest Assistance"
          tabindex="0"
        >
          <table id="privacy-09" className="privacy">
            <tbody>
              <tr>
                <th valign="top">
                  <p>We collect the following categories of personal information…</p>
                </th>
                <th valign="top">
                  <p>We collect this information in order to…</p>
                </th>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Description of complaint or inquiry</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Respond to your complaint or inquiry</li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Name</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Look up and verify your identity</li>
                    <li className="a">
                      Search our records for information about your past stays and membership in the
                      Hilton Honors loyalty program
                    </li>
                    <li className="a">Provide customer service recovery and support</li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li className="a">Address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Verify your identity</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Email address</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Verify your identity</li>
                    <li className="a">Provide follow-up communications regarding your inquiry</li>
                    <li className="a">Perform analytics to improve business operations</li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Authenticate mobile account</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                  </ul>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <ul>
                    <li className="a">Phone number</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li className="a">Verify your identity</li>
                    <li className="a">Provide follow-up communications regarding your inquiry</li>
                    <li className="a">Authenticate mobile account</li>
                    <li className="a">Send service-related SMS communications</li>
                    <li className="a">Provide customer service and support</li>
                    <li className="a">Detect and prevent fraud</li>
                    <li className="a">Share this information with Hilton Partner Accommodations</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="a">Minors</h3>
        <p>
          Hilton does not knowingly collect, disclose, or sell personal information of individuals
          under the age of majority in their respective jurisdiction without permission from a
          minor’s parent or guardian. When searching for hotel reservations on a Hilton website or
          on the Hilton Honors app, you may be asked to provide the ages of any minor guests staying
          in the room. Hilton collects this information for the purpose of determining room rates
          where applicable, such as at an “all-inclusive” property.
        </p>
        <h3 className="a">Closed Circuit Television (CCTV)</h3>
        <p>
          We use closed circuit television and other security measures at our properties that may
          capture or record images of guests and visitors in public areas, as well as information
          related to your location while on our properties (via keycards and other technologies) for
          the protection of our staff, guests and visitors to our properties, in accordance with
          applicable laws that require us to collect information that is used to protect guests,
          employees, and visitors of the property. We retain CCTV in accordance with our data
          retention policies, as necessary to fulfill the purpose for which that information was
          collected, or as required or permitted by law. You may be able to request access to CCTV
          images within the time specified by applicable law and as required by law.
        </p>
        <p>
          Franchised hotels are operated by entities that are separate from Hilton. To request
          access to CCTV images from a franchised hotel, please contact that hotel. In addition to
          Hilton Domestic Operating Company Inc., those entities also are independent data
          controllers of CCTV images. When permitted by law, we may charge an appropriate fee to
          cover the costs of responding to the request. Such requests may be submitted by accessing
          the Data Subject Rights Requests Portal at{' '}
          <a href="https://www.hilton.com/en/hilton-honors/personal-data-requests/">
            datarights.hilton.com
          </a>{' '}
          or in writing to{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a> or
          Hilton Data Protection Officer, 7930 Jones Branch Drive, McLean, VA 22102, USA.
        </p>

        <br />
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />

        <h2>
          <a name="PartnerAccommodations"></a>
          Reservations with Hilton Partner Accommodations
        </h2>
        <p>
          When making a reservation with AutoCamp or Small Luxury Hotels of the World (SLH) through
          a Hilton channel, Hilton will provide AutoCamp or SLH with certain guest information for
          the exclusive purpose of fulfilling the reservation, as identified in this Statement.
          Hilton channels include Hilton’s website, Hilton Honors application, or Mini Programs. The
          guest information will be processed and retained in accordance with AutoCamp’s or SLH’s
          privacy policies and as permitted by applicable law. You can access AutoCamp’s privacy
          policy{' '}
          <a href="https://autocamp.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
            here
          </a>
          . You can access SLH’s privacy policy{' '}
          <a
            href="https://slh.com/about-slh/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
        <br />
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />

        {/* Section 4 */}
        <h2>
          <a name="Cookies" />
          Cookies and Other Technologies
        </h2>
        <p>
          Like many other websites, our site may use "Cookies" or "Other Technologies" (such as
          "pixel tags," "web beacons," "clear GIFs," links in emails, JavaScript, device IDs
          assigned by Google or Apple, or similar technologies). Cookies and Other Technologies
          allow us and third parties to obtain information about your visits to the site, including
          to analyze your visiting patterns. We use this information to process your reservations or
          requests and to deliver online and mobile advertisements, messages and content for us and
          others that are specific to your interests. Depending on your jurisdiction, prior consent
          for the use of non-essential Cookies may be required. If it is the case, we will obtain
          your consent before using Cookies on your device.
        </p>
        <p>
          Cookies are small text files that are stored on your computer or mobile device ("device")
          when you visit a website. Cookies alone cannot be used to disclose your individual
          identity to us, though in some cases we may tie Hilton or third-party Cookies to personal
          information you have provided to us or that we have otherwise obtained about you. Cookies
          may be used on the site in order to improve your experience. For example, we will use
          Cookies to:
        </p>
        <ul>
          <li className="a">
            Remember your user name and password for future visits so that log-in is easier and
            faster;
          </li>
          <li className="a">Remember your language and other preferences;</li>
          <li className="a">Ensure you obtain all requested information;</li>
          <li className="a">Provide a safe and secure service for online transactions;</li>
          <li className="a">
            Track your response to advertisements and website or app content for analysis and the
            number of times we send you the same advertisement; Measure how many people use the
            site, and how they use it, so that we may keep it running quickly and efficiently; and
          </li>
          <li className="a">
            Help us and others deliver communications and content to you that are relevant and
            responsive to your interests and location.
          </li>
        </ul>
        <p>Below we explain the different types of Cookies that may be used on the site.</p>
        <ul>
          <li className="a">
            Essential Cookies – Essential Cookies are necessary for the site to work, and enable you
            to move around it and use its services and features. Disabling these Cookies may make
            the services and features unavailable.
          </li>
          <li className="a">
            Functional Cookies – We use Functional Cookies to save your settings on the
            site—settings such as your language preference, or booking information you have
            previously used when booking a hotel with us. We also use Functional Cookies to store
            such things as the last hotel you searched for, so that you can easily find it the next
            time you visit. Some Functional Cookies are essential to viewing maps or videos on our
            site.
          </li>
          <li className="a">
            Session Cookies – These types of Cookies are stored only temporarily during a browsing
            session, and are deleted from your device when you close the browser. We use Session
            Cookies to support the functionality of the site and to understand your use of the
            site—that is, which pages you visit, which links you use and how long you stay on each
            page.
          </li>
          <li className="a">
            Persistent Cookies – These types of Cookies are not deleted when you close your browser,
            but are saved on your device for a fixed period of time or until you delete them. Each
            time you visit the site, the server that set the Cookie will recognize the persistent
            Cookie saved on your device. We and others use persistent Cookies to store your
            preferences, so that they are available for your next visit, to keep a more accurate
            account of how often you visit the site, how often you return, how your use of the site
            may vary over time and the effectiveness of advertising efforts.
          </li>
          <li className="a">
            Advertising Cookies – Allow us and other advertisers to show you the most relevant
            products, offers, and advertisements on the site and third-party sites, or through
            emails or other message platforms. For example, some Advertising Cookies help our
            service providers and other advertisers select advertisements that are based on your
            interests, including those expressed or inferred by visits to our websites or apps or
            across other websites, online services, and apps over time. Others help prevent the same
            advertisement from continuously reappearing for you. These types of Cookies also help us
            provide you with content on the site that is tailored to your interests and needs. As
            noted below, some Analytics Cookies and Other Technologies are used in part to
            facilitate advertising.
          </li>
          <li className="a">
            Social Plug-In Cookies – Advertising Cookies also include Social Plug-In Cookies. Social
            Plug-In Cookies are used to share content from the site with members and non-members of
            social media networks such as Facebook, Twitter, YouTube and Pinterest. These Cookies
            are usually set by the social networking provider, enabling such sharing to be smooth
            and seamless.
          </li>
          <li className="a">
            Analytics Cookies – Collect information about your use of the site, and enable us to
            improve the way it works. These Cookies give us aggregated information that we use to
            monitor site performance, count page visits, spot technical errors, see how users reach
            the site, and measure the effectiveness of advertising (including emails we send to
            you).
          </li>
          <li className="a">
            Other technologies may be used for the same purposes as our Cookies, to allow us and
            third parties to know when you visit the site, and to understand how you interact with
            emails or advertisements. Through Other Technologies, non-personal information (e.g.,
            your operating system, your browser version, and the URL you came from) or aggregate
            information may be obtained and used to enhance your experience and understand traffic
            patterns.
          </li>
        </ul>
        <h3>MANAGING COOKIES AND OTHER TECHNOLOGIES</h3>
        <p>
          If you want to remove or block Cookies from your device at any time, you can update your
          browser settings (consult your browser's "help" menu to learn how to remove or block
          Cookies). Hilton is not responsible for your browser settings. You can find good and
          simple instructions on how to manage Cookies on the different types of web browsers at{' '}
          <a href="https://allaboutcookies.org/">www.allaboutcookies.org</a>.
        </p>
        <p>
          Please be aware that rejecting Cookies may affect your ability to perform certain
          transactions on the Site, and our ability to recognize your browser from one visit to the
          next.
        </p>
        <br />
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 5 */}
        <h2>
          <a name="Analytics" />
          Analytics and Interest-Based Advertisements
        </h2>
        <p>
          We partner with certain third-party service providers to collect information to engage in
          analytics, auditing, research, and reporting. &nbsp;These third parties may use server
          logs, web beacons, tags, pixels, and similar technologies, and they may set and access
          Cookies on your computer or other device.
        </p>
        <p>
          In particular, we use Adobe Analytics to help us understand how our customers use our
          websites. We also use Adobe Audience Manager to create audiences for personalizing content
          and advertising experiences. Read more about{' '}
          <a href="https://experienceleague.adobe.com/docs/analytics/technotes/privacy-overview.html">
            how Adobe uses your Personal Information
          </a>{' '}
          in web browsers by Adobe Analytics.
        </p>
        <p>
          We also partner with Amazon Chat which supports our chat platform. We may use Amazon Chat
          for sharing dynamic content for authenticated users.
        </p>
        <p>
          We also partner with third parties to provide advertising services that are targeted based
          on your online activities across websites, mobile apps, and devices over time (commonly
          referred to as “interest-based advertising”). Our advertising partners may collect
          information about your activities on our Services on your current device and combine it
          with information about your activities on other websites, mobile apps, and devices. They
          may collect such information using server logs, Cookies, web beacons, tags, pixels, mobile
          advertising IDs (such as Facebook Cookies or Google's Advertising ID), cross-device
          linking, and similar technologies. For example, our advertising partners may use the fact
          that you visited our website to target advertising to you on other websites and mobile
          apps on your current device or on other devices you use. They may match your browsers or
          devices if you log into the same online service on multiple devices or if your devices
          share similar attributes that support an inference that they are used by the same person
          or household. This means that information about your activity on websites or apps on your
          current browser or device may be combined and used with information collected from your
          other browsers or devices. You can opt out of interest-based advertising in web browsers
          and mobile apps on your current browser or device by following the instructions below.
        </p>
        <p>
          For more information about interest-based advertising and cross-device linking, please
          visit the{' '}
          <a href="https://thenai.org/about-online-advertising/">
            Network Advertising Initiative (“NAI”) website
          </a>{' '}
          and the{' '}
          <a href="http://www.aboutads.info/">Digital Advertising Alliance (“DAA”) website</a>. We
          adhere to the DAA's interest-based advertising principles by providing you enhanced
          notice, transparency, and control of our digital marketing practices as stated at{' '}
          <a href="https://youradchoices.com/principles">http://www.aboutads.info/principles/</a>.
          You may opt out of interest-based advertising and cross-device linking in web browsers and
          mobile apps on your current browser or device by following the instructions below.&nbsp;
        </p>
        <ul>
          <li className="a">
            <strong>Web Browser Opt-Out.</strong> To opt out in web browsers, please visit{' '}
            <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a> and
            <a href="http://optout.networkadvertising.org"> http://optout.networkadvertising.org</a>
            . To help preserve the choices that you make in the DAA's WebChoices page, you can
            install the DAA's “Protect My Choices” extension that is available at{' '}
            <a href="http://www.aboutads.info/PMC">http://www.aboutads.info/PMC</a>.
          </li>
          <li className="a">
            <strong>Mobile Application Opt-Out.</strong>&nbsp; To opt out in mobile apps, you can
            adjust the advertising preferences on your mobile device (for example, in iOS, visit
            Settings &gt; Privacy & Security &gt; Tracking, and in Android, visit Settings &gt;
            Google &gt; Ads &gt; Opt out of interest-based ads). You can also opt out for companies
            that participate in the Digital Advertising Alliance's AppChoices tool by{' '}
            <a href="http://www.aboutads.info/appchoices">downloading the app</a> and following the
            instructions. For more information about opting out on mobile devices, please see{' '}
            <a href="https://www.networkadvertising.org/mobile-choice">here</a>.
          </li>
        </ul>
        <p>
          Please note that the opt-outs described above will apply only to the specific browser or
          device from which you opt out, and therefore you will need to opt out separately on all of
          your browsers and devices. If you delete or reset your cookies or mobile advertising
          identifiers, change browsers (including upgrading certain browsers), or use a different
          device, any opt-out cookie or tool may no longer work, and you will need to opt out again.
          We do not respond to Do Not Track signals at this time.
          <strong> </strong>
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 6 */}
        <h2>
          <a name="ChangingInfo" />
          Changing and Accessing Your Personal Information
        </h2>
        <p>
          If you are a Hilton Honors member, you may review and update the information you provided
          to us at the time of enrollment at any time by{' '}
          <a href="https://www.hilton.com/en/hilton-honors/login/?forwardPageURI=https%3A%2F%2Fwww.hilton.com%2Fen%2F ">
            signing in to your Hilton Honors account
          </a>
          . You may also make changes to your information by contacting Hilton Reservations and
          Customer Care by completing a{' '}
          <a href="https://help.hilton.com/s/contactsupport">support form</a>. If you are located in
          the United States or Canada, you can call customer care toll free by dialing +
          1-888-4HONORS (888-446-6677). Customers located outside of the United States and Canada
          can review a list of customer care phone numbers{' '}
          <a href="https://help.hilton.com/s/article/Global-support-numbers">here</a>.
        </p>
        <p>
          You may be able to request that we inform you about the personal information we maintain
          about you and, where appropriate, withdraw your consent for certain data processing
          activity and/or request that we update, correct, delete, and/or stop processing your
          personal information. We will make all required updates and changes within the time
          specified by applicable law and as required by law. When permitted by law, we may charge
          an appropriate fee to cover the costs of responding to the request. Such requests may be
          submitted by accessing the Data Subject Rights Requests Portal at{' '}
          <a href="https://www.hilton.com/en/hilton-honors/personal-data-requests/">
            datarights.hilton.com
          </a>{' '}
          or in writing to{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a> or
          Hilton Data Protection Officer, 7930 Jones Branch Drive, McLean, VA 22102, USA.
        </p>
        <p>
          In addition, in some circumstances you may request that we cease sharing personal
          information about you with our business partners or that Hilton cease using personal
          information about you by contacting us using the email or mailing address above.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />

        {/* Section 7 */}
        <h2>
          <a name="Protecting" />
          How We Protect Personal Information
        </h2>
        <p>
          We take reasonable measures to: (i) protect personal information from unauthorized access,
          disclosure, alteration, or destruction, and (ii) keep personal information accurate and
          up-to-date as appropriate.
        </p>
        <p>
          Hilton employs a robust team of dedicated information security professionals who are
          responsible for managing Hilton's security program. This team is responsible for, among
          many other things, monitoring our systems for potential intrusions, responding to
          potential incidents, supporting property-level information security, regularly reviewing
          and updating the security controls Hilton uses to protect data, and providing training on
          Hilton's information security program.
        </p>
        <p>
          Hilton maintains a payment card industry (“PCI”) compliance program and an Information
          Technology compliance program. This compliance program generates audit reports concerning
          the adequacy and effectiveness of Hilton's Technology internal controls, including a PCI
          Attestation of Compliance signed by an external PCI Qualified Security Assessor and a
          SSAE16/SOC1 report addressing the Technology general controls over systems that support
          certain accounting and financial reporting.
        </p>
        <p>
          We require third parties with whom we share personal information to exercise reasonable
          efforts to maintain the confidentiality of personal information.
          <br />
          <br />
          We will never ask you to send us confidential personal information or payment card
          information via email or text message.
        </p>
        <p>
          In the event of a security incident, Hilton will notify regulators and/or consumers as
          required by applicable laws and regulations.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 8 */}
        <h2>
          <a name="Disclosure" />
          Disclosure Pursuant to Legal Obligations or to Protect the Safety and Security of People
          and Property
        </h2>
        <p>
          Hilton will disclose personal information as required by law. Examples of such disclosures
          include when countries require Hilton to collect personal data about visitors to
          properties in that country, when a law enforcement agency serves a valid subpoena on
          Hilton, and when a civil litigant serves a lawful discovery request on Hilton. Hilton may
          elect to share personal information with law enforcement, employers, those who host events
          with Hilton, or others as necessary to protect the safety and security of people and
          property, to pursue available remedies or limit the damages that we may sustain, and to
          respond to an emergency.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 9 */}
        <h2>
          <a name="Marketing" />
          Marketing Communications Choices
        </h2>
        <p>
          We want to make you aware of the fantastic products we offer! To do so, we may send you
          communications via email, text message, push notifications, in-app alerts, direct mail,
          and social media. In addition to traditional text messages, we offer the opportunity to
          receive transactional and promotional messages via text message by using a short code. A
          short code is a five-digit number that you can message to opt-in to receive such text
          messages. We may also send you marketing communications via WhatsApp. To enable us to send
          you such communications, your information may be disclosed to service providers who use
          such information solely to send out marketing communications on our behalf. Furthermore,
          short code opt-in data and consent will not be shared with any other third parties for
          their own marketing or advertising purposes. If, depending on the circumstances, consent
          is required in your jurisdiction, we will obtain your consent before sending you such
          communications.
        </p>
        <p>
          If you are a Hilton Honors member, you may change the communications you receive from us
          by logging on to your online account and managing your subscriptions; by writing to us
          (and including your email address) at Hilton Data Protection Officer, 7930 Jones Branch
          Drive, McLean, VA 22102, USA; or by emailing us at{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a>.
        </p>
        <p>
          If you prefer not to receive email marketing materials from us, you may opt-out at any
          time by using the unsubscribe function in any email you receive from us or by clicking
          this link:{' '}
          <a href="https://secure.hilton.com/en/hhonors/optout/unsubscribe.jhtml?listid=0">
            https://secure.hilton.com/en/hhonors/optout/unsubscribe.jhtml?listid=0
          </a>
          , by writing to us (and including your email address) at Hilton Data Protection Officer,
          7930 Jones Branch Drive, McLean, VA 22102, USA, or by emailing us at{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a>.
          Opt-out requests can take up to ten business days to be effective.
        </p>
        <p>
          To opt out of text or WhatsApp messages, reply “STOP” to the message you received or to
          opt out of text messages from a specific hotel, tell the hotel front desk that you do not
          want to receive text messages from the hotel .
        </p>
        <p>
          You may control whether our mobile apps send you push notifications by changing your
          notification settings on your mobile device. If we engage in sending you in-app messages,
          we will allow control for those in our apps' settings.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 10 */}
        <h2>
          <a name="IntlTransfers" />
          International Transfers of Personal Information
        </h2>
        <p>
          As a global company, we endeavor to provide you with the same level of service that you
          have come to expect at Hilton whether you are in San Francisco, London or Tokyo. To
          provide this service, you acknowledge that we may share your personal information among
          members of the Hilton Portfolio of Brands, our service providers, and other third parties,
          which may be located in countries outside of your own.
        </p>
        <p>
          When you stay at a Hilton property outside the United States, the data controller for that
          property transfers the personal information relating to your reservation to Hilton in the
          United States pursuant to data transfer agreements when required by applicable laws or
          regulations. The data controller may also maintain a local copy of your personal
          information when so required by applicable laws or regulations. Although the data
          protection laws of various countries may differ from those in your own country, we will
          take appropriate steps to ensure that your personal information is handled as described in
          this Statement and in accordance with the law.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 11 */}
        <h2>
          <a name="DataRetention" />
          Data Retention Periods
        </h2>
        <p>
          We retain personal information about you necessary to fulfill the purpose for which that
          information was collected or as required or permitted by law. When we destroy your
          personal information, we do so in a way that prevents that information from being restored
          or reconstructed.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 12 */}
        <h2>
          <a name="Revisions" />
          Changes to this Statement
        </h2>
        <p>
          We may modify this Statement from time to time. When we make material changes to this
          Statement we will post a link to the revised Statement on the homepage of our site. You
          can tell when this Statement was last updated by looking at the date at the top of the
          Statement. Any changes to our Statement will become effective upon posting of the revised
          Statement on the site. Use of the site, any of our products and services, and/or providing
          consent to the updated Statement following such changes constitutes your acceptance of the
          revised Statement then in effect.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 13 */}
        <h2>
          <a name="ContactUs" />
          Contact Information for Questions or Concerns
        </h2>
        <p>
          If you have any questions or concerns, please contact us by sending an email to{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a>, by
          sending a letter to Hilton Data Protection Officer, 7930 Jones Branch Drive, McLean, VA
          22102, or by calling our toll-free number: (800) 413-7470.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 14 */}
        <h2>
          <a name="CaliforniaRights" />
          Additonal Provisions Applicable to California Consumers
        </h2>
        <h3 className="a">
          The Right to Know, The Right to Delete, and the Right to Opt-Out of the Sale or Sharing of
          Personal Information
        </h3>
        <p>
          The California Consumer Privacy Act (“CCPA”) affords California consumers (1) the right to
          know what personal information we collect, use, disclose, and/or sell; (2) the right to
          request that we delete their personal information; and (3) the right to request that we no
          longer sell or share their personal information.
        </p>
        <p>
          If you would like information about the personal information that we collect, disclose,
          sell and/or share about you, or if you would like to make a request for us to delete or to
          stop selling your personal information, please visit our website at{' '}
          <a href="https://www.hilton.com/en/hilton-honors/personal-data-requests/">
            datarights.hilton.com
          </a>{' '}
          to submit your request. You also may call our toll-free telephone number: (800) 413-7470,
          email the Data Protection Office (“DPO”) at{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a>, send
          a letter to the Data Protection Office at 7930 Jones Branch Drive, McLean, Virginia, USA
          22102, or complete a paper form available from the front desk at any of our hotels. In
          addition to these methods, California consumers may request that we no longer sell their
          personal information by{' '}
          <a href="https://www.hilton.com/en/hilton-honors/personal-data-requests/">
            completing a personal data request form
          </a>
          .
        </p>
        <p>
          When the DPO receives your request, the DPO will first verify your identity. If you are a
          Hilton Honors member, the DPO will verify your identity by asking you to provide your
          name, Hilton Honors account number, and the email address and phone number associated with
          your Hilton Honors account. If you are not a Hilton Honors member, the DPO will verify
          your identity by asking you to provide your name, the email address and/or phone number
          associated with a past stay, and potentially a confirmation number from one of your stays
          at a Hilton property. Once the DPO has verified your identity, the DPO will promptly
          fulfill your request
        </p>
        <p>
          If you would like, you may designate an authorized agent to make a request on your behalf.
          To designate an authorized agent, please submit an order issued by a court, a document
          submitted by a barred attorney, or a formal certified document issued by an official
          governmental agency.
        </p>
        <p>
          If you would like to opt out of the sale of your personal information, you may do so by
          clicking on the banner that appears on any Hilton website when you access that site from
          an IP address that relates to California or by visiting our website at{' '}
          <a href="https://www.hilton.com/en/hilton-honors/personal-data-requests/">
            datarights.hilton.com
          </a>{' '}
          or click the “Do Not Share or Sell My Information” link at the bottom of any Hilton
          website to submit your request. Please note that when you opt out of Cookies, tags, and
          pixels, that opt out only pertains to the device and the browser that you are using when
          you opt out. If you wish to opt out for other devices or browsers, you must opt out again
          when you are using those devices or browsers.
        </p>
        <h3 className="a">Sensitive Personal Information</h3>
        <p>
          We limit the use of your personal information to that use which is necessary to perform
          reasonably expected services or as required by law.
        </p>
        <h3 className="a">Direct Marketing Disclosure</h3>
        <p>
          California consumers also may request information about our disclosures of certain
          categories of personal information to third parties for their direct marketing purposes.
          Such requests must be submitted to us at one of the following addresses:{' '}
          <a href="mailto:CA_Privacy@Hilton.com">CA_Privacy@hilton.com</a> or{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a>, send
          a letter to the Data Protection Office at 7930 Jones Branch Drive, McLean, Virginia, USA
          22102. Within thirty days of receiving such a request, we will provide a list of the
          categories of personal information disclosed to third parties for third-party direct
          marketing purposes during the immediately preceding calendar year, along with the names
          and addresses of these third parties. This request may be made no more than once per
          calendar year. We reserve our right not to respond to requests submitted to addresses
          other than the addresses specified in this paragraph.
        </p>
        <h3 className="a">The Right to Non-Discrimination</h3>
        <p>
          The CCPA prohibits a business from treating a consumer differently because the consumer
          exercised a right conferred on him/her by the CCPA. We welcome you to exercise your rights
          under the CCPA, and we will not discriminate against you for doing so.
        </p>
        <h3 className="a">Financial Incentive Notice</h3>
        <p>
          Hilton does not offer a financial incentive for the collection, sale, or deletion of
          personal information. As detailed in this Statement, Hilton primarily collects personal
          data from guests in order to fulfill guest reservations and from Honors members in order
          to operate the Hilton Honors loyalty program. Hilton also collects personal data for
          marketing and other purposes as permitted by applicable law.
        </p>
        <p>
          As set forth in the Hilton Honors{' '}
          <a href="https://www.hilton.com/en/hilton-honors/terms/">Terms and Conditions</a>, Hilton
          Honors members have the ability to accumulate points and access discounted rates. The
          ability to accumulate points and to access discounted rates is not directly related to the
          value provided to the business of members&apos; data.
        </p>
        <h3 className="a">Removal of Content</h3>
        <p>
          If you are a California resident under the age of 18, and a registered user of any site
          where this policy is posted, California Business and Professions Code Section 22581
          permits you to request and obtain removal of content or information you have publicly
          posted. To make such a request, please send an email with a detailed description of the
          specific content or information to{' '}
          <a href="mailto:CA_Privacy@Hilton.com">CA_Privacy@hilton.com</a>. Please be aware that
          such a request does not ensure complete or comprehensive removal of the content or
          information you have posted and that there may be circumstances in which the law does not
          require or allow removal even if requested.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 15 */}
        <h2>
          <a name="NevadaRights" />
          Additional Provision Applicable to Nevada Consumers
        </h2>
        <h3 className="a">The Right to Opt-Out of the Sale of Personal Information</h3>
        <p>
          If you are a Nevada resident, you may request that we stop selling certain categories of
          personal information that we collect. To submit a request please visit our website at{' '}
          <a href="https://www.hilton.com/en/hilton-honors/personal-data-requests/">
            datarights.hilton.com
          </a>{' '}
          to submit your request. You also may call our toll-free telephone number: (800) 413-7470,
          email the Data Protection Office (“DPO”) at{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a>, send
          a letter to the Data Protection Office at 7930 Jones Branch Drive, McLean, Virginia, USA
          22102, or complete a paper form available from the front desk at any of our hotels. When
          the DPO receives your request, the DPO will first verify your identity. If you are a
          Hilton Honors member, the DPO will verify your identity by asking you to provide your
          name, Hilton Honors account number, and the email address and phone number associated with
          your Hilton Honors account. If you are not a Hilton Honors member, the DPO will verify
          your identity by asking you to provide your name, the confirmation number from one of your
          stays at a Hilton property, and the email address associated with that stay. Once the DPO
          has verified your identity, the DPO will promptly fulfill your request.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 16 */}
        <h2>
          <a name="FrenchRights" />
          Additional Provision Applicable to French Residents
        </h2>
        <p>
          French residents: as disclosed in this Statement, we may collect your phone number to
          fulfill guest reservations, send guests communications relating to their reservations,
          provide customer service and support, verify identity, send marketing communications via
          SMS, process transactions with partners, share data with Hilton Grand Vacations,
          authenticate mobile accounts, send service-related SMS communications, detect and prevent
          fraud, and engage in sales activities for group bookings. We will not use your phone
          number for telemarketing. Nonetheless, if you would like to register on the list of
          opposition to telephone canvassing, you may do so at{' '}
          <a href="https://www.bloctel.gouv.fr/">www.bloctel.gouv.fr</a>.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 17 */}
        <h2>
          <a name="HongKongRights" />
          Additional Provision Applicable to Hong Kong Consumers
        </h2>
        <p>
          If you are based in Hong Kong, we will obtain your consent on or before sending you any
          marketing messages. You may opt out of receiving marketing messages from us at any time by
          using the unsubscribe link found in our marketing messages.
        </p>
        <p>
          If you would like to access or correct your personal information, please contact the Data
          Protection Officer (“DPO”) at{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a>, or
          complete the paper form available from the front desk at any of our hotels. We may require
          further information from you in order to verify your identity before processing your
          request. In certain circumstances where permitted to do so under applicable law, we may
          refuse to fulfil your request.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 18 */}
        <h2>
          <a name="KoreanDataRights" />
          Additional Provision Applicable to Korean Data Subjects
        </h2>
        <h3>Local Agent for Information and Communication Services</h3>
        <span>
          <span style={{ fontWeight: 'bold' }}>
            <em>Local Agent.</em>
          </span>{' '}
          You may contact our Local Agent below by phone, email or mail to handle questions and
          complaints in connection with the processing of your personal data if you are in Korea.
          <ul>
            <li className="a" style={{ listStylePosition: 'outside', marginBottom: 0 }}>
              Local Agent: Jung Byung-hyuk (Brad Jeong), Managing Director, Conrad Seoul
            </li>
            <li style={{ listStyleType: 'none', marginBottom: 0 }}>
              Telephone: <a href="tel:+02-6137-7600">02-6137-7600</a>
            </li>
            <li style={{ listStyleType: 'none', marginBottom: 0 }}>
              Email: <a href="mailto:Brad.jeong@conradhotels.com">Brad.jeong@conradhotels.com</a>
            </li>
            <li style={{ listStyleType: 'none', marginBottom: 0 }}>
              Address: 10 Gukjegeumyuong-ro Yeouido, Seoul, 07326, South Korea
            </li>
          </ul>
          <br />
        </span>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />

        {/* Section 19 */}
        <h3 align="center" className="appendix">
          <a name="AppendixA" />
          APPENDIX A<br />
          ADDITIONAL PROVISIONS APPLICABLE TO PROCESSING OF PERSONAL
          <br />
          INFORMATION OF RESIDENTS OF CERTAIN COUNTRIES AND US STATES
        </h3>
        <br />
        <p>
          This Appendix provides additional information for individuals residing in countries and
          U.S. states that have enacted privacy laws giving certain additional rights to residents
          of those jurisdictions. Once effective, the U.S. states include, but are not limited to,
          California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Montana, Nevada,
          Oregon, Tennessee, Texas, Utah, and Virginia. These countries include, but are not limited
          to, those in the European Economic Area (EEA), the United Kingdom and Switzerland, as well
          as Australia, Brazil, China, Korea, Hong Kong, Monaco, Israel, Japan, Nigeria and Türkiye.
          This Appendix outlines certain additional information that Hilton is obligated to provide
          to you, as well as certain rights you have with respect to the processing of your personal
          information, pursuant to applicable local laws. This Appendix will control to the extent
          it conflicts with any provision in the main body of this Statement.
        </p>
        <p>
          <u>Controller</u>: Find more information on the Hilton entities that process your personal
          information at{' '}
          <a href="https://hiltonhonors3.hilton.com/en/policy/data-controllers.html">
            Data Controllers
          </a>
          .
        </p>
        <p>
          <u>Data Protection Officer</u>: Hilton's Data Protection Officer may be contacted by email
          at <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a>,
          or at the following address:
        </p>
        <p>
          Attn: Data Protection Officer <u>7930 Jones Branch Drive, McLean, VA 22102 USA</u>
        </p>
        <h4>Purposes and Legal Basis for Processing</h4>
        <p>
          Hilton processes your personal information for the purposes set forth in the section
          titled Collection of Personal Information - Generally and the section titled Collection of
          Personal Information - Notice at Point of Collection of the main body of this Statement.
        </p>
        <p>
          The legal bases for Hilton's processing activities include processing such information as
          necessary to comply with our contractual obligations, compliance with our legal
          obligations, protecting the safety of our employees, guests and others, for our legitimate
          business interests, and pursuant to your consent.
        </p>
        <p>
          The particular legal basis for the processing of your personal information is based on the
          purpose for which such information was provided or collected:
        </p>
        <p>
          <em>Hilton Honors Participation: </em>We process the personal information obtained in
          connection with your participation in the Hilton Honors program on the basis of our
          contractual relationship with you, consent if legally required, and for pursuing our
          legitimate business interests, including to personalize your use of our services and
          applications, to communicate news and promotional items, and to deliver personalized
          advertising and content.
        </p>
        <p>
          <em>Surveys: </em>Completion of surveys is voluntary – we process the information obtained
          from surveys on the basis of your consent and in furtherance of our business interests,
          including marketing, service improvements, and analytics.
        </p>
        <p>
          <em>On-property Collection:</em> When you make a reservation and when you stay at one of
          our hotel properties, we process your name, address, contact information, along with the
          details of your stay (arrival and departure day and time, vehicle information and
          information regarding others traveling or staying with you), on the basis of our
          contractual relationship with you.
        </p>
        <ul>
          <li className="a">
            We also process such data for pursuing our legitimate business interests, with your
            consent if legally required, including for marketing, service improvements,
            administration of our e-Folio program, and analytics and service personalization, as
            described in the sections referenced above of our Global Privacy Statement.
          </li>
          <li className="a">
            We collect certain additional personal information during registration/check-in at our
            properties (such as national ID or passport information), as necessary to comply with
            our legal obligations.
          </li>
          <li className="a">
            We use closed circuit television and other security measures at our properties that may
            capture or record images of guests and visitors in public areas, as well as information
            related to your location while on our properties (via keycards and other technologies)
            for the protection of our staff, guests and visitors to our properties.
          </li>
          <li className="a">
            We process personal information in connection with on-property services (such as
            concierge services, health clubs, spas, activities, child care services, equipment
            rental, and our Digital Key functionality), in order to provide the services to you and
            for our business interests including for marketing, service improvements, administration
            of our e-Folio program, and analytics and service personalization, as described in the
            sections referenced above of our Global Privacy Statement.
          </li>
        </ul>
        <p>
          <em>Event Profiles: </em>We process the personal information obtained in connection with
          your event on the basis of our contractual relationship with you and for pursuing our
          legitimate business interests, with your consent if legally required, including for
          marketing, service improvements, and analytics and service personalization, as described
          in the sections referenced above of our Global Privacy Statement.
        </p>
        <p>
          <em>Social Media: </em>Participation in Hilton–sponsored social media activities and
          offerings is voluntary – we process information obtained from social media participation
          on the basis of your consent if legally required and for pursuing our legitimate business
          interests, including for marketing, service improvements, and analytics and service
          personalization, as described in the sections mentioned above of our Global Privacy
          Statement.
        </p>
        <p>
          <em>Promotions and Sweepstakes</em>: Participation in sweepstakes, contests and other
          promotional offerings is voluntary – we process the information obtained from such
          participation based on your consent and as necessary to administer the offering. We also
          use certain data for our business purposes, including for marketing, service improvements,
          administration of our e–Folio program, and analytics and service personalization, as
          described in the sections referenced above of our Global Privacy Statement.
        </p>
        <p>
          <em>Direct Marketing: </em>We use your personal information to send you marketing messages
          on the basis of your consent. You may withdraw your consent for direct marketing
          communications at any time by contacting us at{' '}
          <a href="mailto:customer_privacy@Hilton.com">customer_privacy@Hilton.com</a> or by
          following the unsubscribe instructions in the marketing message, or by logging in to your
          Hilton Honors account and updating your communication preferences.
        </p>
        <p>
          <em>Franchise and Ownership Opportunities: </em>We process this information on the basis
          of our contractual relationship with you and for our related legitimate business
          interests, including maintaining and promoting the Hilton brand and facilitating direct
          communication between properties within the Hilton Portfolio of Brands.
        </p>
        <p>
          <em>WMBE Suppliers: </em>Participation in Hilton's Supplier Diversity Program is voluntary
          – we process this information based on your consent and for our related business
          legitimate interests, including maintaining and enhancing our diversity program.
        </p>
        <p>
          <em>Retention</em>: We retain personal information about you for the time necessary to
          accomplish the purpose for which such information was collected, usually for the duration
          of any contractual relationship and for any period thereafter as legally required or
          permitted by applicable law. Our retention policies reflect applicable statute of
          limitation periods and legal requirements.
        </p>
        <h3>Data Subject Rights</h3>
        <p>
          Some regional, national, and state laws confer rights relating to personal data as
          indicated in the Personal Data Requests Portal. Residents of certain jurisdictions may
          have the following rights.
        </p>
        <p>
          <em>Access, Correction and Erasure Requests</em>: You have the right to:
        </p>
        <ul>
          <li className="a">
            ask us to confirm whether we are processing your personal information and receive
            information on how your data is processed
          </li>
          <li className="a">obtain a copy of your personal information</li>
          <li className="a">request that we update or correct your personal information</li>
          <li className="a">
            request that we delete personal information in certain circumstances
          </li>
        </ul>
        <p>
          <em>Right to Object to Processing</em>: You have the right to request that Hilton cease
          processing of your personal information: for marketing activities, including profiling
        </p>
        <ul>
          <li className="a">for statistical purposes</li>
          <li className="a">
            where such processing is based on our legitimate business interests, unless we are able
            to demonstrate a compelling legitimate basis for such processing or we need to process
            your personal information for the establishment, exercise or defense of a legal claim
          </li>
        </ul>
        <p>
          <em>Right to Restrict Processing</em>: You have the right to request that Hilton limit the
          processing of your personal information:
        </p>
        <ul>
          <li className="a">
            while Hilton is evaluating or in the process of responding to a request by you to update
            or correct your personal information where such processing is unlawful and you do not
            want Hilton to delete your data
          </li>
          <li className="a">
            where Hilton no longer requires such data, but you want us to retain the data for the
            establishment, exercise or defense of a legal claim
          </li>
          <li className="a">
            where you have submitted an objection to processing based on our legitimate business
            interests, pending our response to such request
          </li>
        </ul>
        <p>
          Where we limit the processing of your personal information pursuant to your request, we
          will inform you prior to re-engaging in such processing.
        </p>
        <p>
          <em>Right to Object to Sale and Share</em>: You have the right to request that Hilton not
          sell or share your personal information. Please visit our website at{' '}
          <a href="https://www.hilton.com/en/hilton-honors/personal-data-requests/">
            datarights.hilton.com
          </a>{' '}
          to submit your request or click on the Do Not Share or Sell My Information link at the
          bottom of any Hilton website. You also may call our toll-free telephone number: (800)
          413-7470, email the Data Protection Office (“DPO”) at{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a>, or
          send a letter to the Data Protection Office at 7930 Jones Branch Drive, McLean, Virginia,
          USA 22102.
        </p>
        <p>
          <em>Data Portability Requests</em>: You have the right to request that we provide you or a
          third party that you designate with certain of your personal information in a commonly
          used, machine readable format. Please note, however, that data portability rights apply
          only to personal information that we have obtained directly from you and only where our
          processing is based on consent or the performance of a contract.
        </p>
        <p>
          <em>Submitting Requests: </em>Your requests may be submitted by accessing the{' '}
          <a href="https://www.hilton.com/en/data-subject-rights/">
            Data Subject Rights Request Portal
          </a>{' '}
          or in writing to{' '}
          <a href="mailto:DataProtectionOffice@hilton.com">DataProtectionOffice@hilton.com</a>, or
          the Hilton Data Protection Officer, 7930 Jones Branch Drive, McLean, VA 22102, USA. You
          may also update your personal information as provided in section titled (Changing and
          Accessing Your Personal Information) of the main body of this Global Privacy Statement.
        </p>
        <p>
          We will respond to all such requests within the time period permitted by law. Please note,
          however, that certain personal information may be exempt from such rights pursuant to
          applicable data protection laws. In addition, we will not respond to any request unless we
          are able to appropriately verify the requester's identity. We may charge you a reasonable
          fee for subsequent copies of data that you request.
        </p>
        <p>
          If you want to present a complaint or have concerns about our data practices or the
          exercise of your rights, you may either contact Hilton at{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a> or
          the supervisory authority in the Member State of your residence. For the EEA, the
          supervisory authorities are available at the{' '}
          <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">
            European Data Protection Board’s website
          </a>
          . For the UK, the supervisory authority is available at{' '}
          <a href="https://ico.org.uk/">ico.org.uk</a>.
        </p>
        <p>
          <em>Right to Withdraw Consent: </em>You have the right to withdraw your consent to any
          processing that we conduct solely based on your consent (such as sending direct marketing
          materials to your personal email account). You may withdraw your consent to marketing
          activities by following the instructions on any marketing emails, or contacting{' '}
          <a href="mailto:customer_privacy@hilton.com">customer_privacy@hilton.com</a>. For any
          other activities for which you have previously consented, you may contact{' '}
          <a href="mailto:DataProtectionOffice@hilton.com">DataProtectionOffice@hilton.com</a> to
          withdraw such consent.
        </p>
        <p>
          <em>
            Right to Object to Segmentation (also referred to as profiling) and Automated Decision
            Making
          </em>
          : You have the right to object to segmentation or profiling and automated decision making.
          We use personal information to divide large groups of consumers into sub-groups of
          consumers (known as segments) based on some type of shared characteristics such as
          geography, behavior, or demographics.
        </p>
        <p>
          With your consent, we make automated decisions, meaning without human interference, using
          segmentation and/or your specific personal information to offer you certain benefits based
          on your characteristics (such as discounted room rates or other special offers based on
          your geography, behavior, or demographics). For example, if you travel frequently during
          the week to hotels in France, we may send you special offers for Hilton hotels in France.
        </p>
        <p>
          <em>International Data Transfers</em>: We may transfer the personal information we collect
          about you pursuant to the purposes described in this Statement to countries that have not
          been found by the European Commission to provide adequate protection. In particular, we
          transfer your personal information to the United States.
        </p>
        <p>
          We use appropriate safeguards for the transfer of personal information among our
          affiliates in various jurisdictions, and where required, we have implemented data transfer
          agreements, such as the European Commission standard contractual clauses or other such
          safeguards for such purposes. To obtain a copy of theses clauses or additional information
          on transfers, you may send your request to{' '}
          <a href="mailto:privacy@hilton.com">privacy@hilton.com</a>.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 20 */}
        <h3 align="center">
          <a name="AppendixB" />
          APPENDIX B<br />
          ADDITIONAL PROVISIONS APPLICABLE TO PROCESSING OF PERSONAL
          <br />
          INFORMATION OF DATA SUBJECTS IN TÜRKIYE
        </h3>
        <br />
        <p>
          For data subjects in Türkiye, this Appendix outlines certain additional information that
          Hilton is obligated to provide to you, as well as certain rights you have with respect to
          the processing of your personal information, pursuant to applicable local laws. This
          Appendix will control to the extent it conflicts with any provision in the main body of
          this Statement, or Appendix A, as applicable.
        </p>
        <p>
          Data Controller. Hilton Domestic Operating Company Inc. is the Hilton entity for all guest
          data and Hilton's marketing activities.
        </p>
        <p>
          Your personal data may be processed by other Hilton entities in Türkiye who act as a data
          controller based on the Hilton property you stay at. In such cases, the relevant Hilton
          entity will act as the data controller for your personal data, who will provide you with a
          separate privacy notice, where necessary.
        </p>
        <p>
          Data Controller Representative. You may contact our data controller representative in
          Türkiye by email or mail to handle questions and complaints in connection with the
          processing of your personal data if you are in Türkiye.
        </p>
        <ul>
          <li className="a">
            Esin Attorney Partnership
            <br />
            <a href="mailto:info@esin.av.tr">info@esin.av.tr</a>
            <br />
            Akatlar Mah. Ebulula Mardin Cad. Gül Sok. No. 2
          </li>
        </ul>
        <p>
          <em>Legal bases for the processing your personal data. </em>Hilton relies on the following
          legal bases while processing your personal data for the purposes indicated in the Global
          Privacy Statement in accordance with Article 5 of the Law No. 6698 on Protection of
          Personal Data (“Data Protection Law”):
        </p>
        <ul>
          <li className="a">
            processing of your personal data is necessary provided that it is directly related to
            the conclusion or fulfilment of the contract between you and Hilton;
          </li>
          <li className="a">
            processing is mandatory for Hilton to be able to comply with our legal obligations;
          </li>
          <li className="a">
            processing is mandatory for Hilton’s legitimate interests, provided that it does not
            violate your fundamental rights and freedoms; or
          </li>
          <li className="a">
            your explicit consent. (Please note that we rely on your explicit consent only when we
            require your consent for the processing and we do not rely on this legal basis if and
            when we rely on one of the other legal grounds above.)
          </li>
        </ul>
        <p>
          <em>Your rights. </em>You have the rights under Article 11 of the Data Protection Law and
          may exercise your rights by contacting us at:{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a> or
          Data Protection Officer, Hilton, 7930 Jones Branch Drive, McLean, VA 221102, USA.
        </p>
        <p>
          The requests found in your application will be resolved as soon as possible according to
          the nature of your request and within thirty days at the latest free of charge. However,
          if your request incurs additional cost to our company, then you may be charged over the
          tariff fee determined by the Turkish Personal Data Protection Board.
        </p>
        <p>
          <em>Amendments. </em>The following sections of the Global Privacy Statement do not apply
          to individuals in Türkiye: The following sentence at the second paragraph:{' '}
          <em>
            By using any of our products or services and/or by agreeing to this Statement, e.g. in
            the context of registering for any of our products or services, you understand and
            acknowledge that we will collect and use personal information as described in this
            Statement.
          </em>
        </p>
        <p>
          The following sentence in the International Personal Data Transfers section:{' '}
          <em>
            To provide this service, you acknowledge that we may share your personal information
            among members of the Hilton Portfolio of Brands, our service providers, and other third
            parties, which may be located in countries outside of your own.
          </em>
        </p>
        <p>
          <em>Language. </em>This Statement is available in English and Turkish. If there is any
          inconsistency or different interpretation between the English and Turkish versions, the
          Turkish text shall prevail.
        </p>
        <p>
          <em>International Data Transfers. </em>Hilton is required to transfer your personal data
          to its affiliates outside of Türkiye, which includes Hilton Domestic Operating Company
          Inc., all resident in the United States, as well as their in-country suppliers, in order
          to complete reservations, process Hilton Honors memberships and conduct promotion
          activities through its website, whose servers are located abroad. Without your consent,
          Hilton is unable to provide you with its services through this website. Therefore, we ask
          for your consent when you access this website for the cross-border transfer of your data.
        </p>
        <p>
          This consent practice is only temporary, and Hilton is working on effecting an undertaking
          letter, which is one of the alternative legal mechanisms for cross-border data transfers.
          Accordingly, the consent practice will be terminated once the undertaking letter is
          prepared and approved by the Personal Data Protection Authority.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 21 */}
        <h3 align="center">
          <a name="AppendixC" />
          APPENDIX C<br />
          ADDITIONAL PROVISIONS APPLICABLE TO PROCESSING OF PERSONAL
          <br />
          INFORMATION OF CHINA RESIDENTS OR CITIZENS
        </h3>
        <br />
        <h4 className="a">1. Introduction</h4>
        <p>
          For individuals who reside in the People's Republic of China (for the purposes of this
          Privacy Statement, excluding Hong Kong Special Administrative Region, Macau Special
          Administrative Region and Taiwan) ("China") or citizens of the People's Republic of China,
          we understand that privacy is important to you. Therefore, in this Appendix, we provide
          you with an introduction to how Hilton collects, uses, discloses, and otherwise processes
          your personal information (including sensitive personal information, where applicable)
          that we receive or collect from you. Please read this Statement carefully before using any
          of Hilton's services or products.
        </p>
        <p>
          This Appendix does not replace the main body of this Statement, but supplements the main
          body of this Statement, and should be read together with the main body and Appendix A, as
          applicable. In the event of any conflict or inconsistency between this Appendix and the
          main body of this statement, this Appendix shall prevail.
        </p>
        <p>
          “Personal Information” shall have the same meaning as defined in the Personal Information
          Protection Law of the People's Republic of China (as revised from time to time). Such
          information can be recorded electronically or in other ways and can identify the identity
          or reflect the activity of a specific natural person alone or in combination with other
          information, including sensitive personal information. "Personal Information" or
          "Information" used in the main body of this Statement and in this Appendix also includes
          Sensitive Personal Information (where applicable). “Sensitive Information” shall have the
          same meaning as defined in the Personal Information Protection Law of the People&apos;
          Republic of China (as revised from time to time). The Sensitive Personal Information
          involved in this Statement includes{' '}
          <strong>
            passport or ID card information, payment card information, accommodation information
            (such as arrival time), web browsing records, and precise positioning information
          </strong>
          . You agree that the sensitive personal information will be processed in accordance with
          the purposes and methods set forth in this Statement.
        </p>
        <h4 className="a">2. Collection and Use of Personal Information</h4>
        <p>
          Regarding the following issues, please refer to the section titled Collection of Personal
          Information – Generally and Collection of Personal Information – Notice at Point of
          Collection in the main body of this Statement.
        </p>
        <ul>
          <li className="a">
            The types of personal information that we collect from you (including sensitive personal
            information, which include the information that may applicable as set out in the section
            titled Collection of Personal Information – Notice at Point of Collection) in the main
            body of this Statement.
          </li>
          <li className="a">
            How we collect and use your personal information, the purposes for which we use it, and
            how we share or disclose your personal information.
          </li>
        </ul>
        <p>
          Hilton will not knowingly collect or disclose personal information from minors under the
          age of 16. When we collect personal information from an individual under the age of 16, we
          need to obtain consent from his/her parent or guardian and we will process such
          information in accordance with the safeguards set out in applicable laws and regulations.
        </p>
        <p>
          We will use your personal information with your consent or as otherwise required or
          permitted by applicable law. If we want to use your personal information for other
          purposes, we will obtain your further consent. We will explain when it is necessary for
          you to provide your personal information, and the result if you provide.
        </p>
        <h4 className="a">3. Disclosure and Sharing of Personal Information</h4>
        <p>
          With your consent, we may share your personal information for the purposes described in
          this Statement with members of the members of Hilton worldwide brand portfolio, our
          service providers, and other third parties as follows:
        </p>
        <ul>
          <li className="a">
            Hilton Worldwide Holdings Inc. and its subsidiaries, and members of the Hilton brand
            portfolio, including franchised and managed hotels,
          </li>
          <li className="a">Service providers providing services on Hilton&apos;s behalf,</li>
          <li className="a">Payment card providers and processors,</li>
          <li className="a">Employers specified in the company agreements,</li>
          <li className="a">
            Advertising networks and analytics providers for Hilton&apos;s website and mobile
            applications.
          </li>
        </ul>
        <p>
          To provide and optimize our services, third-party software development kits (SDKs) are
          embedded in our applications (including WeChat Mini Programs). The following lists the
          names of the third-party SDKs we currently embed, the types of personal information
          collected, and the purpose of collection and use. If you have questions about the
          collection of your personal information by the third parties through the SDKs, please
          refer to the privacy policies of such third parties.
        </p>
        <div role="region" aria-label="Third Party SDK's used" tabindex="0">
          <table id="privacy-10" className="privacy">
            <tbody>
              <tr>
                <th valign="top" width="20%">
                  <p>Name of the SDK</p>
                </th>
                <th valign="top" width="20%">
                  <p>Provider of the SDK</p>
                </th>
                <th valign="top" width="20%">
                  <p>Purposes of the SDK</p>
                </th>
                <th valign="top" width="20%">
                  <p>Application where the SDK is embedded</p>
                </th>
                <th valign="top" width="20%">
                  <p>Types of personal information collected by the SDK</p>
                </th>
                <th valign="top" width="20%">
                  <p>Link to the privacy policy of the SDK</p>
                </th>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <p>Alipay "App Payment" SDK</p>
                </td>
                <td valign="top">
                  <p>Alipay (China) Network Technology Co., Ltd.</p>
                </td>
                <td valign="top">
                  <p>Payment</p>
                </td>
                <td valign="top">
                  <p>iOS, Android</p>
                </td>
                <td valign="top">
                  <p>
                    Common device information (IMEI/IMSI, SIM card serial number/MAC address,
                    android id (only for Android devices)), network information
                  </p>
                </td>
                <td valign="top">
                  <p>
                    <a
                      href="https://opendocs.alipay.com/open/01g6qm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://opendocs.alipay.com/open/01g6qm
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>WeChat Pay</p>
                </td>
                <td valign="top">
                  <p>Tencent</p>
                </td>
                <td valign="top">
                  <p>Payment</p>
                </td>
                <td valign="top">
                  <p>iOS, Android, WeChat Mini-Program</p>
                  <p>&nbsp;</p>
                </td>
                <td valign="top">
                  <p>WeChat User OpenId</p>
                </td>
                <td valign="top">
                  <p>
                    <a
                      href="https://weixin.qq.com/cgi-bin/readtemplate?t=weixin_business_pay"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://weixin.qq.com/cgi-bin/readtemplate?t=weixin_business_pay
                    </a>
                  </p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <p>Adobe Analytics SDK for WeChat Mini-Program</p>
                </td>
                <td valign="top">
                  <p>Adobe</p>
                </td>
                <td valign="top">
                  <p>User clicks and usage records</p>
                </td>
                <td valign="top">
                  <p>WeChat Mini-Program</p>
                </td>
                <td valign="top">
                  <p>Location information, device information, version of operating system</p>
                </td>
                <td valign="top">
                  <p>
                    <a
                      href="https://github.com/NJRojas/aep-sdks-documentation/tree/master/resources/adobe-experience-platform-mini-programs-sdk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://github.com/NJRojas/aep-sdks-documentation/tree/master/resources/adobe-experience-platform-mini-programs-sdk
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>Adobe Analytics SDK</p>
                </td>
                <td valign="top">
                  <p>Adobe</p>
                </td>
                <td valign="top">
                  <p>User clicks and usage records</p>
                </td>
                <td valign="top">
                  <p>iOS, Android</p>
                </td>
                <td valign="top">
                  <p>Location information, device information, version of operating system</p>
                </td>
                <td valign="top">
                  <p>
                    <a
                      href="https://www.adobe.com/cn/privacy/policy.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.adobe.com/cn/privacy/policy.html
                    </a>
                  </p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <p>Baidu Maps SDK</p>
                </td>
                <td valign="top">
                  <p>Baidu</p>
                </td>
                <td valign="top">
                  <p>Displaying hotels nearby on the map</p>
                </td>
                <td valign="top">
                  <p>Android</p>
                </td>
                <td valign="top">
                  <p>Location information (domestic users)</p>
                </td>
                <td valign="top">
                  <p>
                    <a
                      href="https://lbsyun.baidu.com/index.php?title=open/law"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://lbsyun.baidu.com/index.php?title=open/law
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>Google Maps SDK</p>
                </td>
                <td valign="top">
                  <p>Google</p>
                </td>
                <td valign="top">
                  <p>Displaying hotels nearby on the map</p>
                </td>
                <td valign="top">
                  <p>Android</p>
                </td>
                <td valign="top">
                  <p>Location information (domestic users)</p>
                </td>
                <td valign="top">
                  <p>
                    <a
                      href="https://developers.google.cn/maps/faq?hl=zh-cn#tos_logging"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://developers.google.cn/maps/faq?hl=zh-cn#tos_logging
                    </a>
                  </p>
                </td>
              </tr>
              <tr className="alt">
                <td valign="top">
                  <p>Workspace one</p>
                </td>
                <td valign="top">
                  <p>Omnissa</p>
                </td>
                <td valign="top">
                  <p>Recording app crashes and error messages</p>
                </td>
                <td valign="top">
                  <p>iOS, Android</p>
                </td>
                <td valign="top">
                  <p>Device information, version of operating system</p>
                </td>
                <td valign="top">
                  <p>
                    <a
                      href="https://www.omnissa.com/omnissa-workspace-one-privacy-disclosure/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.omnissa.com/omnissa-workspace-one-privacy-disclosure/
                    </a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          Despite the above situations, we may disclose personal information to third parties to:
          comply with applicable laws or effective legal procedures; respond to enquiries of the
          governments or requests of the public and/or the government authorities; protect rights,
          privacy, security or property of Hilton, site visitors, guests, employees or the public;
          pursue or exercise our rights; or respond to emergencies.
        </p>
        <h4 className="a">4. Mobile and Location Services</h4>
        <p>
          We may collect your personal information if you download and use our mobile applications
          or use other digital platforms such as our WeChat Mini-Programs. We will obtain your
          consent and collect, use, disclose and otherwise process this information in accordance
          with this Statement. If you allow us to access the location information on your device to
          provide you with our application features and services, such information may include
          geolocation information. We also collect information device information, such as your
          phone brand, model, operating system, operating system version, to direct you to the
          appropriate APP store to download our mobile apps.
        </p>
        <p>
          You can prevent or limit the collection of this information by changing the settings in
          the Hilton Honors APP or by changing the settings in your device.
        </p>
        <h4 className="a">5. Analytics and Interest-based Advertisements</h4>
        <p>
          We also cooperate with third parties to serve targeted advertising based on your online
          activities across different websites, mobile applications, and devices over time. Our
          advertising partners may collect information about your activities related to our services
          on your current device and combine it with information about your activities on other
          websites, mobile applications, and devices. They may collect this information using server
          logs, Cookies, web beacons, tags, pixels, mobile advertising IDs, cross-device linking and
          similar technologies. This may include your personal information.
        </p>
        <p>
          You can opt out interest-based advertising and cross-device linking in the current browser
          or the web browsers and mobile apps on your device. We do not currently respond to "do not
          track" signals. For more information, please see the section titled Analytics and
          Interest-Based Advertising as well as the section titled Cookies and Other Technologies,
          in the main body of this Statement.
        </p>
        <h4 className="a">
          6. Changing and Accessing Your Personal Information, and Deregistration of Accounts
        </h4>
        <p>
          You have the right to access, correct and/or (in certain circumstances) delete your
          personal information, withdraw consent to our processing of your personal information,
          deregister your membership account (if any), object to the use of your personal
          information in automated decision making and make complaints about how we process your
          personal information. You may have other rights under other applicable laws. Please see
          the contact information in Section 11 below, or the section titled Changing and Accessing
          Your Personal Information in the main text of this Statement, to learn how to contact us.
          We seek to handle these requests within the legally required period in accordance with the
          applicable laws.{' '}
          <em>
            Particularly, we will respond to your request to deregister within 15 days of our
            receipt of the request, unless there are extenuating circumstances, in which we will
            inform you of the anticipated timeline if we expect our response to take longer than 15
            days.{' '}
          </em>{' '}
          Please note that if you request to deregister your membership account, you will no longer
          be able to enjoy any membership benefits from the date of deregistration. After you
          deregistered your membership account, your personal information will be deleted or
          anonymized unless the applicable laws require us to retain this information.
        </p>
        <h4 className="a">7. How We Protect Personal Information</h4>
        <p>
          We take reasonable steps to: (i) protect personal information from unauthorized access,
          disclosure, alteration, or destruction, and (ii) keep personal information appropriately
          accurate and current by using appropriate technical and organizational measures. For more
          information, please see the section titled How We Protect Personal Information in the main
          body of this Statement.
        </p>
        <p>
          We are not responsible for the collection, use, disclosure, or otherwise processing of
          personal information by our third-party websites or applications. Please read the privacy
          policies and terms of use of these websites and applications. This applies to: (1)
          in-hotel internet services we may offer; (2) third-party websites and applications to
          which the links in our websites and mobile applications may direct; and (3) third-party
          digital messaging applications or platforms which we use to communicate with you.
        </p>
        <h4 className="a">8. International Transfers of Personal Information</h4>
        <p>
          <strong>
            With your express consent, we may transfer your personal information (including your
            sensitive personal information, if applicable) outside of China, including United
            States, Europe, and other jurisdictions where we, our service providers and other
            relevant third parties conduct business.
          </strong>{' '}
          We will take appropriate steps to protect the integrity and confidentiality of your
          information and to ensure that your personal information is processed in accordance with
          the main body of this Statement and in accordance with the applicable laws.
        </p>
        <h4 className="a">9. Data Retention Period</h4>
        <p>
          We will retain personal information about you for as long as necessary to complete the
          purpose for which it was collected, or as required or permitted by the laws.{' '}
          <em>
            We will destroy your personal information in accordance with our retention and records
            management procedures.{' '}
          </em>{' '}
          For more information, please see the section titled Data Retention Period in the main body
          of this Statement.
        </p>
        <h4 className="a">10. Changes to This Statement</h4>
        <p>
          If we make material changes to this Statement, we will publish these changes. For more
          information, please see the section titled Changes to Statement Content in the main body
          of this Statement. If required by the local laws, we will notify you and/or seek your
          consent.
        </p>
        <h4 className="a">11. Contact Information for Questions or Concerns</h4>
        <p>
          You can contact us if you have any questions, requests, or complaints regarding the
          protection of personal information. You can contact our Data Protection Office at{' '}
          <a href="mailto:DataProtectionOffice@hilton.com">DataProtectionOffice@hilton.com</a>, or,
          if you would like to get in touch with the contact in China, please contact:{' '}
          <a href="mailto:China_Privacy@hilton.com">China_Privacy@hilton.com</a>.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 22 */}
        <h3 align="center">
          <a name="AppendixD" />
          APPENDIX D<br />
          ADDITIONAL PROVISIONS APPLICABLE TO PROCESSING OF PERSONAL
          <br />
          INFORMATION OF BRAZIL RESIDENTS OR CITIZENS
        </h3>
        <br />
        <p>
          To the extent you are located within Brazil or is any form subject to the Brazilian
          General Data Protection Law – Federal Law nº 13709/18 (“LGPD”), the provisions indicated
          below should be read and applied to the processing or your personal data accordingly.
          Unless stated otherwise in this Annex, the remainder of the Policy, including Appendix A
          as applicable, shall not vary and shall remain in full force and effect.
        </p>
        <h4 className="a">1. Data Subjects Rights</h4>
        <p>
          Under certain circumstances, you have the following rights under the LGPD in relation to
          your personal data:
        </p>
        <ul>
          <li className="a">
            <em>Right to Information</em>: right to obtain clear, transparent and understandable
            information on how we use your personal data;
          </li>
          <li className="a">
            <em>Right of confirmation and access</em>: right to receive confirmation and access your
            personal data held by us about you;
          </li>
          <li className="a">
            <em>Right of rectification</em>: right to have your personal data corrected if they are
            outdated, inaccurate or incorrect and/or to complete them;
          </li>
          <li className="a">
            <em>Right of anonymization, blocking or deletion</em>: Anonymize, block or eliminate any
            unnecessary or excessive data or data processed in noncompliance with the applicable
            law;
          </li>
          <li className="a">
            <em>Right to withdraw consent at any time for consent-based data processing</em>: right
            to withdraw your consent to the processing of your personal data when such processing is
            based on consent;
          </li>
          <li className="a">
            <em>Right to data portability</em>: right to request copying, transferring your personal
            data to another database in accordance with further regulation of the local authority;
          </li>
          <li className="a">
            <em>Right to receive information about public and private entities</em>: right to
            receive information about public and private entities with which your personal data is
            shared;
          </li>
          <li className="a">
            <em>Right to object</em>: right to oppose any processing activity based on a legal basis
            other than consent in case there is violation of the LGPD;
          </li>
          <li className="a">
            <em>Right to review automated decisions</em>: right to review of decisions, whenever
            they are based only on automated processing of personal data, by a natural person,
            whenever it affects your interests;
          </li>
          <li className="a">
            <em>Right to file a complaint</em> with your local Data Protection Authority or consumer
            protection entities.
          </li>
        </ul>
        <h4 className="a">2. Legal Bases</h4>
        <p>
          Hilton usually relies on one of the following legal bases when processing your personal
          data for the purposes indicated in the Global Privacy Statement in accordance with Article
          7 of the LGPD:
        </p>
        <ul>
          <li className="a">Data subject’s consent (art. 7, I)</li>
          <li className="a">For compliance of a legal or regulatory obligation (art. 7, II)</li>
          <li className="a">
            Whenever necessary for the performance of a contract or preliminary procedures relating
            to contracts in which the data subject is a party (art. 7, V)
          </li>
          <li className="a">
            Whenever necessary for the legitimate interests of the controller or of third parties,
            except in the prevalence of fundamental rights of the data subject (art. 7, IX).
          </li>
        </ul>
        <p>
          However, depending on the concrete situation, other legal bases set forth in the LGPD may
          apply.
        </p>
        <h4 className="a">3. International Transfers</h4>
        <p>
          Where we transfer information that originates in Brazil to a country outside of Brazil, we
          will take steps to make sure such transfer is carefully managed to protect your privacy
          rights:
        </p>
        <ul>
          <li className="a">
            Transfers within the Hilton Group of Companies may be covered by an agreement entered
            into by members of Hilton (an intra-group agreement) which contractually obliges each
            member to ensure that your information receives an adequate and consistent level of
            protection wherever it is transferred within Hilton in accordance with the LGPD;
          </li>
          <li className="a">
            Where we transfer your data outside of the Hilton Group of Companies, including to other
            companies providing us with a service, we will obtain contractual commitments and
            assurances from them to protect your information, such as standard contractual clauses
            or binding corporate rules. In the lack of instructions from the local supervisory
            authority, we may follow the standard models adopted under the European Union or by
            other competent authorities until then;
          </li>
          <li className="a">
            We are also able to transfer personal information to countries which are recognized as
            providing an adequate level of legal protection or where we can be satisfied that
            alternative arrangements are in place to protect your privacy rights; and
          </li>
        </ul>
        <p>
          Please contact us if you want further information on the specific mechanism used by us
          when transferring your personal data out of Brazil.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
        <br />
        <br />
        <hr />
        <br />
        {/* Section 23 */}
        <h3 align="center">
          <a name="AppendixE" />
          APPENDIX E<br />
          ADDITIONAL PROVISIONS APPLICABLE TO PROCESSING OF PERSONAL
          <br />
          DATA OF DATA SUBJECTS IN THE PRINCIPALITY OF MONACO
        </h3>
        <br />
        <p>
          For data subjects in the Principality of Monaco, this Appendix outlines certain additional
          information that Hilton is obligated to provide to you, as well as certain rights you have
          with respect to the processing of your personal data, pursuant to applicable local laws.
          This Appendix will control to the extent it conflicts with any provision in the main body
          of this Statement or Appendix A, as applicable.
        </p>
        <h4 className="a">1. LEGAL BASES FOR THE PROCESSING OF YOUR PERSONAL DATA</h4>
        <p>
          Hilton relies on legal bases while processing your personal data for the purposes
          indicated in the main body of the Global Privacy Statement in accordance with the
          Monegasque Law No. 1.165 of 23 December 1993 on Protection of Personal Data, as amended
          (the “Data Protection Law”).
        </p>
        <h4 className="a">2. PERSONAL DATA ACCESS AND CUSTOMER RIGHTS</h4>
        <p>
          In accordance with the Data Protection Law, you have a right of access to and a right to
          the rectification, erasure and portability of your personal data as well as a right to
          object and restrict the processing of this personal data. With proof of your identity, you
          may exercise your rights by contacting the Data Protection Officer (“DPO”) by email at{' '}
          <a href="mailto:DataProtectionOffice@Hilton.com">DataProtectionOffice@hilton.com</a>. If,
          after contacting the DPO, you do not feel that your rights have been observed, you may
          make a claim with the French Data Protection Authority (CNIL – Commission Nationale de
          l'Informatique et des Libertés) or the Monegasque authority, the Commission for Control of
          Personally Identifiable Information (CCIN.mc – Commission de Contrôle des Informations
          Nominatives).
        </p>
        <h4 className="a">3. PERSONAL DATA SECURITY</h4>
        <p>
          In case of breach of your personal data liable to pose a high risk to your rights and
          freedoms, Hilton shall inform you as soon as possible and take all necessary measures to
          limit the risks related to this breach. Whether your data is processed by Hilton or by its
          subcontractors and partners, the same security and privacy requirements apply to your
          personal data.
        </p>
        <h4 className="a">4. INTERNATIONAL TRANSFERS OF PERSONAL DATA</h4>
        <p>
          Hilton is required to transfer your personal data to its affiliates outside of the
          Principality of Monaco which includes Hilton Domestic Operating Company Inc., all resident
          in the United States, as well as their in-country suppliers, in order to complete
          reservations, process Hilton Honors memberships and conduct promotion activities through
          its website, whose servers are located abroad.
        </p>
        <p>
          When a subcontractor or partner is located in a country that has not been recognized as
          having an adequate level of protection as the one offered by the Data Protection Law
          (which is the case for the United States), Hilton must fulfil a transfer authorization
          form in order to comply with the Data Protection Law’s own requirements on the security
          and privacy of personal data and the subcontractors should ensure a satisfactory
          protection of your personal data.
        </p>
        <h4 className="a">5. LANGUAGE</h4>
        <p>
          Statement is available in English and French. If there is any inconsistency or different
          interpretation between the English and French versions, the English text shall prevail.
        </p>
        <a style={{ float: 'right' }} href="#toppage">
          Back to Top
        </a>
      </div>
    </>
  );
}
