import type { GetCompanyByAccountIdQuery } from '@dx-ui/cpm-mapping-shared';
import { env } from '@dx-ui/framework-env';
import {
  BrandComponentThemeInline,
  useInitializeConductrics,
  getBrandRouteParams,
} from '@dx-ui/cpm-mapping-shared';
import type { CpmData, SearchUrlParameters, BrandQuery } from '@dx-ui/cpm-mapping-brands';
import {
  useMetrics,
  generateUrl,
  getBaseUrl,
  mapDataToSpecialRates,
  useSingleInstanceQueryStrings,
  isBrandHomePageUrlFormat,
  getAssetPath,
  getBrandSvg,
  getAppVersion,
} from '@dx-ui/cpm-mapping-brands';

import { BrandThemeWrapper } from '@dx-ui/osc-marketing';
import { useRouter } from 'next/router';
import { getVisitorId } from '@dx-ui/framework-react-query';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { LocationProvider } from '@dx-ui/framework-location-provider';
import { Layout } from '@dx-ui/osc-marketing-layout';
import { BrandsSearch, type FormData } from '@dx-ui/osc-brands-search';
import { BrandsHeader } from '@dx-ui/osc-brands-header';

interface LivePageProps {
  brandCode: string;
  brandData: BrandQuery['brand'];
  cpmData: CpmData;
  gatedData: GetCompanyByAccountIdQuery | null;
  localeCode: string;
  isPortfolioHeaderTestActive: boolean;
}

function ConductricsInitalizer() {
  useInitializeConductrics();

  return null;
}

function handleSearchRedirect(searchUrlParameters: SearchUrlParameters) {
  const deepLinkUrl = generateUrl(searchUrlParameters);

  if (isBrowser && deepLinkUrl) {
    window.location.assign(deepLinkUrl);
  }
}

const replaceLang = (stringValue: string, lang: string) => stringValue?.replace('__LANG__', lang);

const getHonorsSignInUrl = (lang: string) => replaceLang(env('DX_AUTH_UI'), lang);
const getHonorsJoinUrl = (lang: string) => replaceLang(env('HONORS_JOIN_URL'), lang);
const getForgotInfoUrl = (lang: string) => replaceLang(env('PARTNER_FORGET_INFO_LINK'), lang);
const getGlobalPrivacyUrl = () => env('GLOBAL_PRIVACY_URL');
const getHonorsAccountUrl = (lang: string) => getBaseUrl(`${lang}/hilton-honors/guest/my-account/`);

export function makePageWrapper(StaticPage: React.FC, forceLocale = 'en') {
  return function PageWrapper({ brandCode, brandData, cpmData, localeCode }: LivePageProps) {
    const router = useRouter();
    const componentParams = {
      aaaRate: false,
      travelAgentRate: false,
      governmentOrMilitaryRate: false,
      seniorRate: false,
      redeemPts: false,
      aarpRate: false,
      deeplinkParameter1: '',
      deeplinkParameter2: '',
      deeplinkParameter3: '',
      deeplinkParameterValue1: '',
      deeplinkParameterValue2: '',
      deeplinkParameterValue3: '',
      displayOption: '',
    };

    const sessionId = getVisitorId();
    const { language: languageCode } = new Intl.Locale(forceLocale ?? localeCode);
    const baseAppUrl = getBaseUrl(`${languageCode}${router.asPath}`);
    const {
      brandSlug,
      brandPath: fullBrandPath,
      auxPageSlug,
    } = getBrandRouteParams(router.asPath, languageCode);

    const metrics = useMetrics(localeCode, fullBrandPath, cpmData.mappedPage, brandData, false);

    const trackUserLoggedIn: Exclude<typeof metrics.trackUserLoggedIn, undefined> = (data) =>
      metrics.trackUserLoggedIn?.(data);

    const {
      cid = '',
      awc = '',
      dclid = '',
      gclid = '',
      wtmcid = '',
    } = useSingleInstanceQueryStrings();

    const onSearchSubmit = (formData: FormData, url: string) => {
      setTimeout(
        () =>
          handleSearchRedirect({
            formData,
            awc,
            cid,
            dclid,
            gclid,
            isGroupSearch: false,
            brandCode,
            languageCode,
            url,
            wtmcid,
            baseAppUrl,
          }),
        0
      );
    };

    const { additionalQS, specialRates } = mapDataToSpecialRates(componentParams, null);
    const { pathname } = new URL(baseAppUrl);
    const isPortfolioHomePage = router.asPath === '/';
    const showLanguageSelector = isPortfolioHomePage;

    if (!brandData) {
      return null;
    }

    return (
      <BrandThemeWrapper brandCodeForTheme={brandCode === 'WW' ? 'HH' : brandCode}>
        <>
          <ConductricsInitalizer />
          <Layout
            isBrandHomePage={isBrandHomePageUrlFormat(pathname, brandCode)}
            ohwBaseUrl={env('OHW_BASE_URL') ?? ''}
            appVersion={getAppVersion()}
            assetPath={getAssetPath()}
            brandSvgPath={getBrandSvg(brandCode)}
            top={
              <BrandsHeader
                trackUserLoggedIn={trackUserLoggedIn}
                megaNavLinks={undefined}
                mainNavLinks={undefined}
                languageCode={languageCode}
                brandCode={brandCode}
                brandFormalName={brandData.formalName ?? undefined}
                brandUrl={brandData.url ?? undefined}
                showLanguageSelector={showLanguageSelector}
                honorsSignInUrl={getHonorsSignInUrl(languageCode)}
                honorsJoinUrl={getHonorsJoinUrl(languageCode)}
                forgotInfoUrl={getForgotInfoUrl(languageCode)}
                globalPrivacyUrl={getGlobalPrivacyUrl()}
                honorsAccountUrl={getHonorsAccountUrl(languageCode)}
                ohwBaseUrl={env('OHW_BASE_URL')}
              />
            }
            bottom={null}
            brandCode={brandCode}
            localeCode={localeCode}
            baseAppUrl={baseAppUrl}
            fullBrandPath={fullBrandPath}
            brandData={brandData}
            seo={cpmData.mappedPage.seo}
            articleTitle={cpmData.mappedPage.articleTitle}
            seoImage={cpmData.mappedPage.seoImage}
            shouldIncludeBackToTopButton={false}
          >
            <LocationProvider api={env('LOCATION_PROVIDER_API')}>
              <BrandComponentThemeInline componentParams={componentParams} brandCode={brandCode}>
                <BrandsSearch
                  isPartnerBrand={false}
                  brandCode={brandCode}
                  brandSlug={brandSlug}
                  baseAppUrl={baseAppUrl}
                  auxPageSlug={auxPageSlug}
                  isBrandRoute={true}
                  fullBrandPath={fullBrandPath}
                  isGroupSearch={false}
                  isDayUse={false}
                  defaultLanguage="en"
                  languageCode={forceLocale}
                  autocompleteUri={env('DX_AUTOCOMPLETE_URI')}
                  additionalQSParameters={additionalQS}
                  specialRates={specialRates}
                  sessionId={sessionId ?? ''}
                  onSearchSubmit={onSearchSubmit}
                />
              </BrandComponentThemeInline>
            </LocationProvider>
            <StaticPage />
          </Layout>
        </>
      </BrandThemeWrapper>
    );
  };
}
