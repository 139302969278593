
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/p/global-privacy-statement",
      function () {
        return require("private-next-pages/p/global-privacy-statement.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/p/global-privacy-statement"])
      });
    }
  